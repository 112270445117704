<main class="main-with-sidebar">
  <div class="article-grid">
    <section class="col-left">
      <app-sidebar></app-sidebar>
    </section>

    <section *ngIf="page" class="col-middle-three">
      <!--Breadcrumbs-->
      <app-breadcrumbs [page]="page"></app-breadcrumbs>

      <!--Title-->
      <div class="article-text">
        <h1 [innerHTML]="page.title"></h1>
        <div *ngIf="page.body" class="body-copy" [innerHTML]="page.body"></div>
      </div>

      <!--Search and Filtering-->
      <div class="page-search-container">
        <input type="text" class="page-search" placeholder="Search {{articleType}}" (keydown)="onKeydown($event)"
               [(ngModel)]="query">
        <button class="page-search-btn" (click)="searchChange()"></button>
      </div>
      <div class="search-show archive-search">
        <div class="search-left">
          <span>Showing {{ posts.length }} article<span *ngIf="posts.length !== 1">s</span></span>
          <a (click)="clearSearch()">Clear search</a>
        </div>
        <app-archive-filters #archiveFilter [articleType]="articleType"
                             (selected)="applyFilters($event)"></app-archive-filters>
      </div>

      <!--Results-->
      <div *ngFor="let post of posts" id="news-container" class="news-item">
        <span class="date">{{ post.published_at }}</span>
        <div style="display:flex;">
          <div *ngIf="post.field_image" class="news-image">
            <img src="{{drupalImageUrl + post.field_image}}" alt="Thumbnail">
          </div>
          <div style="display: inline-block">
            <h4>
              <span class="article-header" [innerHTML]="post.title"
                    routerLink="{{ post.path.includes('/node') ? '/article/' + post.nid : post.path }}"
                    [gaEvent]="{category: 'Archive Listing', action: 'click', label: post.title}"></span>
            </h4>
            <p class="body-copy" [innerHTML]="post.summary"></p>
          </div>
        </div>
        <footer class="archive-card-footer">
          <div class="news-continue clear" [ngClass]="{'clear': !post.tag}">
            <a routerLink="{{ post.path.includes('/node') ? '/article/' + post.nid : post.path }}"
               [gaEvent]="{category: 'Archive Listing', action: 'click', label: post.title}">Continue Reading</a>
            <span class="continue-icon"></span>
          </div>
          <div class="news-label" *ngIf="post.news_tag">
            <ng-container *ngIf="post.field_news_category_icon">
              <img class="news-icon" [src]="getDrupalImageUrl() + post.field_news_category_icon" alt="News Category Icon"/>
            </ng-container>
            <span class="news-tag-label">{{ post.news_tag }}</span>
          </div>
        </footer>
      </div>
      <div *ngIf="posts.length < 1 && !loading" class="no-results">No results.</div>

      <!--Loading Spinner-->
      <div *ngIf="loading" class="loader-fullpage-container">
        <div class="loader"></div>
      </div>
    </section>
  </div>
</main>
