import {AfterViewInit, Component, ViewChild, Input, OnDestroy, OnInit, ElementRef} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";
import {Router} from "@angular/router";
import {LocationService} from "../../service/location/location.service";

@Component({
    selector: 'app-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit, OnDestroy {

    @Input() topLevel: boolean;
    @ViewChild('ancContainer', {static: true}) announcementsRef: ElementRef;

    private locationSub;
    public announcements = [];
    public loading = false;
    public currentPip = 0;

    constructor(
        private drupalService: DrupalService,
        private router: Router,
        private locationService: LocationService
    ) {
    }

    ngOnInit() {
        this.getContent(this.locationService.getLocation());
        this.locationSub = this.locationService.locationStream.subscribe(loc => this.getContent(loc));
    }

    ngOnDestroy() {
        this.locationSub.unsubscribe();
    }

    /**
     * Get announcements by current location and trim fields
     * @param loc
     */
    getContent(loc: string) {
        this.loading = true;
        this.currentPip = 0;
        this.drupalService.getArticlePreview(window['ANNOUNCEMENTS_LABEL'], [], loc).then(response => {
            const anc = response.slice(0, window['NUM_ANNOUNCEMENTS']);
            for (let item of anc) {
                // Trim fields so that display doesn't become unreasonably
                if (item.title.length > 80) {
                    item.title = item.title.substring(0, 80) + "..."
                }
                if (item.body_trimmed.length > 80) {
                    item.body_trimmed = item.body_trimmed.substring(0, 80) + "..."
                }
            }
            this.announcements = anc;
            this.loading = false;
        });
    }

    getAnnouncementsLabel() {
        return window['ANNOUNCEMENTS_LABEL'];
    }

    // Move forward one announcement
    pipForward() {
        this.transitionToPip(((this.currentPip) + 1) % this.announcements.length);
    }

    // Move backward one announcement
    pipBackward() {
        const newPip = this.currentPip > 0 ? this.currentPip - 1 : this.announcements.length - 1;
        this.transitionToPip(newPip);
    }

    getLocation() {
        return this.locationService.getLocation();
    }

    // Helper to transition to certain announcement
    transitionToPip(i: number) {
        if (i === this.currentPip) {
            return;
        }
        // Animation direction
        const direction = i < this.currentPip ? 'prev' : 'next';
        const classList = this.announcementsRef.nativeElement.classList;
        //Animate
        classList.remove('in', 'next', 'prev');
        classList.add('out', direction);
        setTimeout(() => {
            classList.remove('out', 'next', 'prev');
            classList.add('in', direction);
            this.currentPip = i;
        }, 180);
    }

    // Navigate user to article
    readMore() {
        const article = this.announcements[this.currentPip];
        const url = article.path.includes('/node/') ? `/article/${article.nid}` : article.path;
        this.router.navigateByUrl(url);
    }
}
