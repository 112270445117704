import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit{

  public smallHomeSize = false;
  public tinyHomeSize = false;

  constructor(
  ){}

  ngOnInit(){
    this.adjustScreensize(window);
  }

  // Listens for resize window event
  onResize(event) {
    this.adjustScreensize(event.target);
  }

  // Evaluates screen size to determine which home layout to show
  adjustScreensize(window){
    this.smallHomeSize = (window.innerWidth <= window['MAX_LARGE_HOME_SIZE']);
    this.tinyHomeSize = (window.innerWidth <= window['MAX_SMALL_HOME_SIZE']);
  }

}
