import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteResolverService} from "../service/route-resolver/route-resolver.service";
import {SearchResultsComponent} from "./search-results/search-results.component";
import {ArchiveComponent} from "./archive/archive.component";
import {CalendarMonthComponent} from "./calendar-month/calendar-month.component";
import {HistoricalPollsComponent} from "./historical-polls/historical-polls.component";
import {ArticleComponent} from "./article/article.component";
import {HomeComponent} from "./home/home.component";
import {CommonComponent} from "./common.component";
import {CanLoadSiteService} from "../service/can-load-site/can-load-site.service";

const routes: Routes = [
  {
    path: '', component: CommonComponent, canActivateChild: [CanLoadSiteService], children: [
      {path: 'home', component: HomeComponent, resolve: {pageData: RouteResolverService}, data: {state: 'home'}},
      {
        path: 'article/:id',
        component: ArticleComponent,
        resolve: {pageData: RouteResolverService},
        data: {state: 'article'}
      },
      {
        path: 'calendar',
        component: CalendarMonthComponent,
        resolve: {pageData: RouteResolverService},
        data: {state: 'calendar'}
      },
      {
        path: 'previous_polls',
        component: HistoricalPollsComponent,
        resolve: {pageData: RouteResolverService},
        data: {state: 'polls'}
      },
      {
        path: 'search/:query',
        component: SearchResultsComponent,
        resolve: {pageData: RouteResolverService},
        data: {state: 'search'}
      },
      {
        path: 'search',
        component: SearchResultsComponent,
        resolve: {pageData: RouteResolverService},
        data: {state: 'search'}
      },
      {
        path: 'archive/:type',
        component: ArchiveComponent,
        resolve: {pageData: RouteResolverService},
        data: {state: 'archive'}
      },
      {path: '', redirectTo: '/home', pathMatch: 'full'},
      {path: '**', component: ArticleComponent, resolve: {pageData: RouteResolverService}, data: {state: 'alias'}},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class RWCommonRoutingModule {

}
