import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class DrupalService {

  constructor(private http: HttpClient) {
  }

  /**
   * Makes a request to drupal based on type and optional nid and returns an Observable
   * @param type
   * @param id
   * @returns {Observable<Object>}
   */
  get(type, id?) {
    let url = window['DRUPAL_URL'];
    url += type;
    if (id) {
      url += '/' + id;
    }
    url += "?_format=json";
    return this.http.get<Array<any>>(url).toPromise();
  }

  /**
   * Gets children of page by node id
   * @param sort
   * @param id
   * @returns {Observable<Array<any>>}
   */
  getChildren(sort = '', id) {
    let view = 'children';
    if (sort.toLowerCase() === 'recently updated') {
      view = 'recent_children';
    } else if (sort.toLowerCase() === 'authored on') {
      view = 'created_children';
    }
    let url = window['DRUPAL_URL'] + view + '?parent_id=' + id + '&_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  getPoll() {
    let url = window['DRUPAL_URL'] + 'angular/getpoll';
    return this.http.get<Array<any>>(url).toPromise();
  }

  castVote(pid, vote) {
    let url = window['DRUPAL_URL'] + 'angular/castvote/' + pid + '/' + vote;
    return this.http.get<Array<any>>(url).toPromise();
  }

  cancelVote(pid) {
    let url = window['DRUPAL_URL'] + 'angular/cancelvote/' + pid;
    return this.http.get<Array<any>>(url).toPromise();
  }

  getPastPolls(page, start, end, query) {
    let url = window['DRUPAL_URL'] + 'angular/historicalResults/' + page + '/' + start + '/' + end + '/' + query;
    return this.http.get<Array<any>>(url).toPromise();
  }

  /**
   * Do a drupal query with subqueries
   * @param type
   * @param queries
   * @returns {Promise<Array<any>>}
   */
  getWithFilters(queries) {
    let results = [];
    return this.getWithFiltersHelper(queries, 0, queries.length, results);
  }

  /**
   * Returns promise of content type filtered by queries
   * @param type
   * @param queries
   * @param index
   * @param length
   * @param results
   * @returns {Observable<Object>}
   */
  private getWithFiltersHelper(queries, index, length, results) {
    let url = window['DRUPAL_URL'] + "content" + "?";
    for (let pair of queries[index].query) {
      url += pair.term + '=';
      for (let value of pair.values) {
        url += value + ",";
      }
      url = url.substr(0, url.length - 1);
      url += "&";
    }
    url = url.substr(0, url.length - 1);
    url += "&_format=json";
    return this.http.get<Array<any>>(url).toPromise()
      .then(response => {
        index++;
        let newResults = results.concat(response);
        if (index === length) {
          return newResults;
        } else {
          return this.getWithFiltersHelper(queries, index, length, newResults);
        }
      });
  }

  /**
   * This method is called on archive pages when the user has scrolled to the bottom and needs to load more results
   * @param articleType
   * @param offset
   * @param filters
   * @returns {Promise<Array<any>>}
   */
  getMoreArchive(articleType, offset, filters?) {
    let url = window['DRUPAL_URL'] + 'content_summary?article_type=' + articleType + "&type=ReynoldsWorld%20Article";
    if (filters && filters.length) {
      filters.forEach(filter => url += `&${filter.term}=${filter.values}`);
    }
    url += "&offset=" + offset + '&_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  /**
   * This method pulls in summarized article summary data to be shown as previews in article lists
   * @param articleType
   * @param filters
   * @param loc
   */
  getArticlePreview(articleType?, filters?, loc?) {
    let url = window['DRUPAL_URL'] + 'content_summary?&type=ReynoldsWorld%20Article';
    if (articleType) {
      url += '&article_type=' + articleType;
    }
    if (loc) {
      url += '&location=' + loc;
    }
    if (filters && filters.length) {
      filters.forEach(filter => {
        url += `&${filter.term}=${filter.values}`;
      });
    }
    url += '&_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  checkNodeExists(nid) {
    let url = window['DRUPAL_URL'] + 'verify?nid=' + nid + "&_format=json";
    return this.http.get<Array<any>>(url).toPromise();
  }

  getBlogHomepage(articleType) {
    let url = window['DRUPAL_URL'] + 'content?article_type=' + articleType + "&homepage=1&_format=json";
    return this.http.get<Array<any>>(url).toPromise();
  }

  getMenu(loc, week) {
    const url = `${window['DRUPAL_URL']}rest_resource/get_menu?location=${loc}&week=${week}&_format=json`;
    return this.http.get<Array<any>>(url).toPromise();
  }

  getMedia(id) {
    let url = window['DRUPAL_URL'] + 'rw_media?mid=' + id + '&_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  getForms() {
    let url = window['DRUPAL_URL'] + 'angular/getforms';
    return this.http.get<Array<any>>(url).toPromise();
  }

  getForm(id) {
    let url = window['DRUPAL_URL'] + 'angular/getform/' + id;
    return this.http.get<Array<any>>(url).toPromise();
  }

  submitForm(data: any) {
    const options: any = {responseType: 'text', withCredentials: true};
    return this.http.post(`${window['DRUPAL_URL']}angular/sendSignature`, data, options)
      .pipe(catchError((error) => {
        console.error(error)
        return throwError('Error sending ajax request');
      })).toPromise();
  }

  getHeaderCategories() {
    const url = window['DRUPAL_URL'] + 'header?_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  getGroupEmail(group) {
    const url = window['DRUPAL_URL'] + 'group_email?name=' + group + '&_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  getAssocSpotlightTags() {
    const url = window['DRUPAL_URL'] + 'assoc_spotlight_tags?_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  getNewsArticleTags() {
    const url = window['DRUPAL_URL'] + 'news_article_tags?_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  getPageTitle(id) {
    const url = window['DRUPAL_URL'] + 'node_title?nid=' + id + '&_format=json';
    return this.http.get<Array<any>>(url).toPromise();
  }

  getLandingsBySubheader(subheader) {
    const url = window['DRUPAL_URL'] + 'header_content?header_category=' + subheader + "&_format=json";
    return this.http.get<Array<any>>(url).toPromise();
  }

  getNode(id) {
    return this.getWithFilters([{
      query: [{
        term: "nid",
        values: [id]
      }]
    }]);
  }

  getNodeByAlias(alias) {
    return this.getWithFilters([{
      query: [{
        term: "path_alias",
        values: [alias]
      }]
    }])
  }
}
