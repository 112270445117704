import {Component} from '@angular/core';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  constructor(private datePipe: DatePipe) {
  }

  getYear() {
    return this.datePipe.transform(new Date(), 'YYYY');
  }
}
