<main class="main-with-sidebar" (window:resize)="adjustScreenSize($event.target)">
  <div class="article-grid" *ngIf="pageLoaded">

    <!-- Sidebar -->
    <section class="col-left">
     <app-sidebar [id]="pageLoaded.nid"></app-sidebar>
    </section>

    <!-- Content -->
    <article class="col-middle-three">
      <app-breadcrumbs [page]="pageLoaded"></app-breadcrumbs>
      <span class="page-favorite page-tool" *ngIf="pageLoaded" (click)="toggleQuickLink()" [gaEvent]="{category: 'Favorite Toggle', action: 'click', label: pageLoaded.title}">
        <i class="fa" [ngClass]="{'fa-star-o throb' : !isQuickLink, 'fa-star pulse' : isQuickLink}"></i>
        <span *ngIf="isQuickLink">Remove Quick Link</span>
        <span *ngIf="!isQuickLink">Add Quick Link</span>
      </span><br>
      <div class="news-label" *ngIf="pageLoaded.news_tag">
        <ng-container *ngIf="pageLoaded.field_news_category_icon">
          <img class="news-icon" [src]="getDrupalImageUrl() + pageLoaded.field_news_category_icon" alt="News Category Icon"/>
        </ng-container>
        <span class="news-tag-label">{{ pageLoaded.news_tag }}</span>
      </div>
      <div class="article-text">
        <h1 *ngIf="pageLoaded" [innerHTML]="pageLoaded.title"></h1>
        <div *ngIf="pageLoaded; else loading" class="body-copy" [innerHTML]="pageLoaded.body"></div>
        <ng-template #loading>Loading...</ng-template>
      </div>

      <!-- Media -->
      <ng-container *ngIf="!!pageLoaded.field_media">
        <app-media [mediaId]="pageLoaded.field_media"></app-media>
      </ng-container>

      <!-- Form -->
      <ng-container *ngIf="!!pageLoaded.field_form">
        <app-form [formId]="pageLoaded.field_form"></app-form>
      </ng-container>

      <p class="edited-date" *ngIf="pageLoaded">Published on {{pageLoaded.published_at}}</p>

      <!-- Responsive size of resources links -->
      <aside class="small resources-small" style="margin-top:40px;" *ngIf="pageLoaded && responsive && children && children.length > 0">
        <app-resources [title]="pageLoaded.title" [children]="children" [responsive]="true"></app-resources>
      </aside>

    </article>

    <!-- Resources links -->
    <aside style="margin-top:40px;" class="col-right" *ngIf="pageLoaded && !responsive && children && children.length > 0">
      <app-resources [title]="pageLoaded.title" [children]="children" [responsive]="false"></app-resources>
    </aside>
  </div>
</main>
