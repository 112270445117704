import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {


    @Output() selectOption: EventEmitter<any> = new EventEmitter();

    @Input() id: string;
    @Input() title: string;
    @Input() options: [];

    constructor() { }

    ngOnInit() {
    }

    select(option) {
        this.selectOption.emit(option);
    }

}
