import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../service/auth/auth.service";

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html'
})
export class AuthErrorComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  retryLogin() {
    this.authService.redirectUriAfterLogin = '/home';
    this.authService.login();
  }

}
