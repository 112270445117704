<section class="card">
  <h3>Current News</h3>
  <div class="news">
    <!-- Stand-ins -->
    <ng-container *ngIf="!pages || pages.length === 0">
      <div *ngFor="let page of getNumStandins()" class="news-item">
        <div class="standin-date"></div>
        <div class="standin-header"></div>
        <div class="standin-body-copy"></div>
        <hr>
        <div class="standin-news-footer"></div>
      </div>
    </ng-container>
    <!-- News items -->
    <div *ngFor="let page of pages; let i = index" class="news-item">
      <!--Article Card Info-->
      <span class="date">{{ page.published_at }}</span>
      <div style="display:flex">
        <div *ngIf="page.field_image" class="news-image">
          <img src="{{getDrupalImageURL() + page.field_image}}" alt="">
        </div>
        <div style="display:inline-block">
          <h4>
            <span class="article-header" [innerHTML]="page.title" routerLink="/article/{{page.nid}}"
                  [gaEvent]="{category: 'Current News Widget', action: 'click', label: page.title}"></span>
          </h4>
          <p class="body-copy" [innerHTML]="page.summary"></p>
        </div>
      </div>
      <!--Article Card Footer-->
      <footer class="news-footer">
        <div class="news-label" *ngIf="page.news_tag">
          <ng-container *ngIf="page.field_news_category_icon">
            <img class="news-icon" [src]="getDrupalImageURL() + page.field_news_category_icon" alt="News Category Icon"/>
          </ng-container>
          <span class="news-tag-label">{{ page.news_tag }}</span>
        </div>
        <div class="news-continue">
          <a routerLink="/article/{{page.nid}}"
             [gaEvent]="{category: 'Current News Widget', action: 'click', label: page.title}">
            Continue Reading
          </a>
          <span class="continue-icon"></span>
        </div>
      </footer>
    </div>
    <!--Widget Footer-->
    <div style="text-align: right">
      <button class="btn-grey" routerLink="/archive/{{getCurrentNewsLabel()}}"
              [gaEvent]="{category: 'Current News Widget', action: 'click', label: 'View More'}">
        View More
      </button>
    </div>
  </div>
</section>
