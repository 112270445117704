<form class="generated-form">
  <div class="gen-group" *ngFor="let input of inputs">
    <label for="form-input-{{input.name}}">{{input.title}}<span class="optional-mark" *ngIf="input.optional === '1'"> - optional</span></label>

    <input type="text" name="{{input.name}}" id="form-input-{{input.name}}"
           *ngIf="input.field_type !== 'select' && !['phone', 'uk_phone', 'uk_phone_mobile', 'number', 'zip'].includes(input.field_type)" (change)="checkInput($event.target.value.trim(), input)"
           [ngClass]="{'valid' : input.valid, 'invalid' : input.valid === false}" value={{input.start_value}} #textInput>

    <input type="tel" name="{{input.name}}" id="form-input-{{input.name}}"
           *ngIf="['phone', 'uk_phone', 'uk_phone_mobile', 'zip'].includes(input.field_type)" (change)="checkInput($event.currentTarget.value.trim(), input)"
           [ngClass]="{'valid' : input.valid, 'invalid' : input.valid === false}" value={{input.start_value}}>

    <input type="number" name="{{input.name}}" id="form-input-{{input.name}}"
           *ngIf="input.field_type === 'number'" (change)="checkInput($event.currentTarget.value.trim(), input)"
           [ngClass]="{'valid' : input.valid, 'invalid' : input.valid === false}" value={{input.start_value}}>

    <select name="{{input.name}}" id="form-input-{{input.name}}" *ngIf="input.field_type === 'select'" (change)="checkInput($event.currentTarget.value.trim(), input)"
            [ngClass]="{'valid' : input.valid, 'invalid' : input.valid === false}" value={{input.start_value}}>
      <option *ngFor="let option of input.options">{{option}}</option>
    </select>
    <div *ngIf="input.value !== undefined && !input.valid && (input.optional === '0' || input.value.length > 0)" class="required-text">
      <span *ngIf="input.value.length === 0">This field is required.</span>
      <span *ngIf="['phone', 'uk_phone', 'uk_phone_mobile', 'zip'].includes(input.field_type)">
              <span *ngIf="input.max_length && input.value.length > 0">Field must be {{input.max_length}} digits.</span>
      </span>
      <span *ngIf="['phone', 'uk_phone', 'uk_phone_mobile', 'zip'].includes(input.field_type)">
              <span *ngIf="input.max_length && input.value.length > 0">Field should be {{input.max_length}} characters or less.</span>
      </span>
      <span *ngIf="input.name==='email' && input.value.length > 1">Please enter a valid email address.</span>
    </div>
  </div>
  <button class="btn-dark" (click)="submitForm()" style="margin:0" *ngIf="formStatus === 0 || formStatus === 1 && !submitting">Submit</button>
  <div *ngIf="formStatus === 2 && !submitting">
    <button class="btn-dark" (click)="submitForm()" style="margin:0">Try Again</button>
    <div class="error-text">The form encountered an error.</div>
  </div>
  <div class="loader-block-container" *ngIf="submitting">
    <div class="loader"></div>
  </div>
  <h4 *ngIf="formStatus === 1 && !submitting" style="margin-top: 10px;">Response:</h4>
  <div #formResponseText id="form-response" class="form-response-text" hidden></div>
</form>
