import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, throwError} from 'rxjs';
import {AuthService} from "../service/auth/auth.service";
import {catchError, switchMap} from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.isLoggedIn())
      .pipe(
        switchMap(isAuthenticated => {
          if (isAuthenticated) {
            request = request.clone({withCredentials: true});
            return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
          }

          return from(this.authService.renewToken()).pipe(result => {
            if (result) {
              request.clone({withCredentials: true});
              return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
            }
          })
        }));
  }

  private handleAuthError(err: HttpErrorResponse) {
    if (err.status === 401 || err.status === 403) {
      this.authService.login();
    }
    return throwError(err);
  }
}
