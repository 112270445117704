<section class="jumbo-card card top-level-card" [ngClass]="{'video-card' : isVideo}">
  <aside class="col-left" *ngIf="stickyPage">
    <div class="jumbo-image">
      <img src="{{getDrupalImageUrl() + stickyPage.field_image}}" *ngIf="stickyPage.field_image" class="drupal-img">
      <span class="helper"></span>
      <img src="assets/svg/rw_logo.svg" *ngIf="!stickyPage.field_image && !isVideo" class="reyrey-img">
    </div>
  </aside>
  <section class="col-middle">
    <div class="news" *ngIf="stickyPage">
      <span class="date">{{stickyPage.published_at}}</span>
      <h2><span class="article-header" [innerHTML]="stickyPage.title" routerLink="{{stickyPage.path.includes('/node') ? '/article/' + stickyPage.nid : stickyPage.path}}"
                [gaEvent]="{category: 'Jumbo Widget', action: 'click', label: stickyPage.title}"></span></h2>
      <p class="body-copy" [innerHTML]="stickyPage.summary"></p>
      <hr>
      <div class="news-footer">
        <div class="news-label" *ngIf="stickyPage.news_tag">
          <ng-container *ngIf="stickyPage.field_news_category_icon">
            <img class="news-icon" [src]="getDrupalImageUrl() + stickyPage.field_news_category_icon" alt="News Category Icon"/>
          </ng-container>
          <span class="news-tag-label">{{ stickyPage.news_tag }}</span>
        </div>
        <div class="news-continue">
          <a routerLink="{{stickyPage.path.includes('/node') ? '/article/' + stickyPage.nid : stickyPage.path}}"
             [gaEvent]="{category: 'Jumbo Widget', action: 'click', label: stickyPage.title}">Continue Reading</a>
          <span class="continue-icon"></span>
        </div>
      </div>
    </div>
  </section>
</section>
