import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivationEnd, Router} from "@angular/router";
import {DrupalService} from "../../service/drupal/drupal.service";
import {LocationService} from "../../service/location/location.service";
import {AuthService} from "../../service/auth/auth.service";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public smallHeaderSize = false;
  public headerDropdownOpen = false;
  public responsiveDropdownOpen = false;

  public landings = [];
  public subheaders = [];
  public currentSubheaders = [];
  public managementResourcesUrl = window['MR_URL'];
  public query = '';
  public showManagementTab = false;
  public windowWidth:number;

  private routeSub;
  locOptions;

  constructor(
    private router: Router,
    private drupalService: DrupalService,
    private locationService: LocationService,
    private authService: AuthService
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    //Make sure resize is not being triggered by soft keyboard or iOS scroll.
    if (
        document.activeElement
        && (document.activeElement).getAttribute('type') !== 'text'
        && window.innerWidth !== this.windowWidth
    ) {
      this.windowWidth = window.innerWidth;
      this.adjustScreenSize();
    }
  }

  /**
   * Load top-level pages on init, assess screen size
   */
  ngOnInit() {
    this.getHeaderLandings();
    this.authService.getUser().then(userData => {
      const userClass = userData?.profile?.["http://schemas.reyrey.com/identity/claims/jobclass"];
      this.showManagementTab = userClass && window['MANAGER_CLASSES']?.includes(userClass);
    });
    this.locationService.getAllLocations().then(result => this.locOptions = result);
    this.windowWidth = window.innerWidth;
    this.adjustScreenSize();
    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.clickOutsideDropdown(null);
      }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.landings = undefined;
    this.subheaders = undefined;
    this.currentSubheaders = undefined;
  }

  /**
   * Close all headers and evaluate header size
   * @param window
   */
  adjustScreenSize() {
    this.smallHeaderSize = (window.innerWidth <= window['MAX_LARGE_HEADER_SIZE']);
    this.closeHeaderMenu();
    this.headerDropdownOpen = false;
    this.responsiveDropdownOpen = false;
  }

  /**
   * Get top-level navigation links, both header and subheader
   */
  async getHeaderLandings() {
    let headers = await this.drupalService.getHeaderCategories();
    // Loop through once to get top-level headers
    for (let header of headers) {
      if (header.parent_target_id === "") {
        header['subheaders'] = [];
        this.landings.push(header); // If it doesn't have a taxonomy parent, then it is top-level (appears in header)
      }
    }
    // Loop through again to get subheaders
    for (let header of headers) {
      if (header.parent_target_id !== "") {
        let parent = this.getLandingByTitle(header.parent_target_id);
        // Get pages under subheader
        this.drupalService.getLandingsBySubheader(header.name).then(pages => {
          header['pages'] = pages;
        });
        // Get landing page for the subheader itself
        this.drupalService.getLandingsBySubheader(header.parent_target_id).then(pages => {
          for (let page of pages) {
            if (page.title === header.name) {
              header['landing'] = page;
            }
          }
        });
        parent['subheaders'].push(header);
      }
    }
  }

  /**
   * Helper method that returns the header with the given name
   * @param title
   * @returns {any}
   */
  getLandingByTitle(title) {
    for (let header of this.landings) {
      if (header.name === title) {
        return header;
      }
    }
    return null;
  }

  /**
   * Navigates to child page, used because the dropdown links are not anchor tags due to maintaining style guide
   * @param child
   */
  navigateToChild(child) {
    this.closeHeaderMenu();
    this.responsiveDropdownOpen = false;
    this.headerDropdownOpen = false;
    let path = child.path;
    if (child.field_article_type) {
      path = `/archive/${child.field_article_type}`;
    } else if (!path || path.includes('/node/')) {
      path = `/article/${child.nid}`;
    }
    this.router.navigateByUrl(path);
  }

  // Get current location
  getCurrentLocation() {
    return this.locationService.getLocation();
  }

  /**
   * Listens for an ENTER keypress to navigate to search page
   * @param key
   */
  onKeydown(event) {
    if (event.key === 'Enter') {
      this.toggleResponsiveMenu();
      this.search();
    }
  }

  // Navigates to search page with query
  search() {
    this.router.navigateByUrl('/search/' + this.query);
  }

  /**
   * Toggles header submenu
   * @param event
   * @param header
   */
  toggleHeaderMenu(event, header) {
    this.currentSubheaders = [];
    this.closeHeaderMenu(header);
    this.headerDropdownOpen = false;
    header['open'] = !header['open'];
    if (header['open']) {
      this.headerDropdownOpen = true;
      this.getCurrentSubheaders(header);
    }
  }

  /**
   * Close all header menus
   * @param header
   */
  closeHeaderMenu(header?) {
    for (let landing of this.landings) {
      if (header && header.name === landing.name) {
        continue;
      }
      landing['open'] = false;
    }
  }

  // Helper called to close dropdown
  clickOutsideDropdown(event) {
    if(this.responsiveDropdownOpen) {
      this.responsiveDropdownOpen = false;
    }
    if (this.headerDropdownOpen) {
      this.closeHeaderMenu();
      this.headerDropdownOpen = false;
    }
  }

  /**
   * Sets the current subheader (dropdown) options to display based on the given header.
   * @param header
   */
  getCurrentSubheaders(header) {
    this.currentSubheaders = header.subheaders;
  }

  // Toggles mobile dropdown menu
  toggleResponsiveMenu() {
    this.closeHeaderMenu();
    this.responsiveDropdownOpen = !this.responsiveDropdownOpen;
  }

  goHome() {
    this.router.navigateByUrl('/home');
  }

  // Updates the location by passing the service the location string
  changeLocation(locString) {
    this.locationService.changeLocation(locString);
  }

}
