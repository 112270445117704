import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {AnimateValueComponent} from "./flash-poll/animate-value/animate-value.component";
import {AnnouncementsComponent} from "./announcements/announcements.component";
import {AssociateSpotlightComponent} from "./associate-spotlight/associate-spotlight.component";
import {CompanyCalendarComponent} from "./company-calendar/company-calendar.component";
import {CurrentNewsComponent} from "./current-news/current-news.component";
import {FlashPollComponent} from "./flash-poll/flash-poll.component";
import {FoodMenuComponent} from "./food-menu/food-menu.component";
import {HooreyComponent} from "./hoorey/hoorey.component";
import {JumboComponent} from "./jumbo/jumbo.component";
import {QuickLinksComponent} from "./quick-links/quick-links.component";
import {WeatherComponent} from "./weather/weather.component";
import {GaEventDirective} from "../directive/ga-event/ga-event.directive";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
  ],
  declarations: [
    AnimateValueComponent,
    AnnouncementsComponent,
    AssociateSpotlightComponent,
    CompanyCalendarComponent,
    CurrentNewsComponent,
    FlashPollComponent,
    FoodMenuComponent,
    HooreyComponent,
    JumboComponent,
    QuickLinksComponent,
    WeatherComponent,
    GaEventDirective
  ],
  exports: [
    AnimateValueComponent,
    AnnouncementsComponent,
    AssociateSpotlightComponent,
    CompanyCalendarComponent,
    CurrentNewsComponent,
    FlashPollComponent,
    FoodMenuComponent,
    HooreyComponent,
    JumboComponent,
    QuickLinksComponent,
    WeatherComponent,
    GaEventDirective
  ]
})
export class WidgetModule {
}
