<section class="card" *ngIf="poll">
  <h3>
    Weekly Poll
  </h3>
  <div class="poll-title">
    <span class="flash-poll-icon"></span>
    <h4>ReynoldsWorld would like to hear from you.</h4>
  </div>
  <div class="flash-poll-prompt">{{poll.question}}</div>
  <div class="poll-option" *ngFor="let option of poll.options; let i = index">
    <div *ngIf="showResults || selected === null"
         [ngClass]="{'option-container': true, 'selected' : selected == i, 'voted' : selected !== null}"
         (click)="vote(i)">
      <div *ngIf="showResults"
           [@castVote]="{value: (selected === null && !submitted) || !animate ? 'options' : 'results', params:{result_width: poll.results[i] + '%' }}"></div>
      <h5 class="options">{{option['choice']}}</h5>
      <app-animate-value *ngIf="showResults" [hidden]="!submitted" class="counter" [start]="0"
                         [last]="i === (poll.options.length - 1)"
                         [digit]="poll.results[i]" [trigger]="submitted" (triggered)="updateAnimation();"></app-animate-value>
    </div>
  </div>
  <div class="thank-you" *ngIf="!showResults && selected !== null">
    <h5 style="text-align: center">Thank you for your feedback!</h5>
  </div>
  <div *ngIf="submitted && poll.can_cancel && poll.results.length > 0">
    <button class="btn-grey" style="float: right" (click)="cancelVote()">Cancel Vote</button>
  </div>
  <div class="flash-poll-footer">
    <button class="btn-grey" routerLink="/previous_polls"
            [gaEvent]="{category: 'Poll Widget', action: 'click', label: 'View More'}">Previous Polls
    </button>
  </div>
</section>
