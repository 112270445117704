<footer class="rw-footer">
  <div style="-ms-grid-column: 1">
    <span class="reyrey-icon"></span>
    <p class="copyright">Copyright © {{getYear()}} The Reynolds and Reynolds Company. All rights reserved.</p>
  </div>
  <div style="text-align: right;-ms-grid-column: 2">
    <a class="reyrey-feedback" href="mailto:reynoldsworld@reyrey.com">Send Feedback</a>
    <p class="copyright">Questions or comments?</p>
  </div>
</footer>
