import {OnInit, Component, ElementRef, Input, ViewChild, Renderer2} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-company-calendar',
  templateUrl: './company-calendar.component.html'
})
export class CompanyCalendarComponent implements OnInit {

  @Input() isFullpage: boolean;
  @ViewChild('calendarContainer', {static: true}) calendarContainerRef: ElementRef;

  public currentDayEvents = [];
  public currentMonthEvents = [];
  public selectedDay;

  private events = [];
  private month;
  private oldMonth;
  private next;
  private today;
  private week;

  //new
  private now;
  private firstOfMonth;

  constructor(
    private drupalService: DrupalService,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit() {
    this.now = new Date();
    this.today = new Date();
    this.selectedDay = new Date();
    this.firstOfMonth = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.getCalendarEvents().then(() => this.drawMonth());
  }

  updateEventList() {
    this.currentDayEvents = [];
    this.currentMonthEvents = [];

    // List upcoming events
    for (let event of this.events) {
      const startTime = event.start_time ? new Date(event.start_time) : null;
      const endTime = event.end_time ? new Date(event.end_time) : null;
      const startsToday = this.compareDateEquality(startTime, this.selectedDay);
      const endsToday = this.compareDateEquality(endTime, this.selectedDay);
      const startsThisMonth = this.compareDateEquality(startTime, this.firstOfMonth, 'M/y');
      const endsThisMonth = this.compareDateEquality(endTime, this.firstOfMonth, 'M/y');

      if (startsToday && event.all_day === 'On') {
        event.duration = 'ALL_DAY';
        this.currentDayEvents.push(event);
      } else if (startsToday && endsToday) {
        event.duration = 'PART_DAY';
        this.currentDayEvents.push(event);
      } else if (startsToday) {
        event.duration = 'START';
        this.currentDayEvents.push(event);
      } else if (endsToday) {
        event.duration = 'END';
        this.currentDayEvents.push(event);
      }

      if (startsThisMonth || endsThisMonth) {
        this.currentMonthEvents.push(event);
      }
    }
  }

  compareDateEquality(date1: Date, date2: Date, format = 'M/d/y') {
    return this.datePipe.transform(date1, format) === this.datePipe.transform(date2, format);
  }

  formatTime(rawTime) {
    return this.datePipe.transform(new Date(rawTime), 'h:mma');
  }

  /**
   * Format date for full-month calendar view
   */
  formatDate(rawDateTime, rawDateEndTime, allDay) {
    const rawDate = rawDateTime ? new Date(rawDateTime) : null;
    const rawDateEnd = rawDateEndTime ? new Date(rawDateEndTime) : null;

    if (!allDay && rawDateEnd && !this.compareDateEquality(rawDate, rawDateEnd)) {
      return `${this.datePipe.transform(rawDate, 'MMMM d')} - ${this.datePipe.transform(rawDateEnd, 'MMMM d')}`
    } else {
      return this.datePipe.transform(rawDate, 'MMMM d');
    }
  }

  /**
   * Get list of events from drupal
   */
  getCalendarEvents() {
    return this.drupalService.get('calendar').then(response => {
        this.events = this.translateData(response);
        this.updateEventList();
      });
  }

  /**
   * Takes array of events from API request and translates them to have appropriate data types
   * @param events
   * @returns {Array}
   */
  translateData(events) {
    let tEvents = [];
    for (let event of events) {
      let newEvent = {
        title: event.title,
        start_time: event.field_date,
        end_time: event.field_end_time,
        location: event.field_event_location,
        all_day: event.field_all_day,
        page: (event.field_linked_page) ? "/article/" + event.field_linked_page : ""
      };
      if (newEvent.all_day === "On") {
        newEvent.end_time = ""
      }
      tEvents.push(newEvent);
    }
    return tEvents;
  }

  /**
   * Draw month elements
   */
  drawMonth() {
    this.updateEventList();
    if (this.month) {
      this.oldMonth = this.month;
      this.oldMonth.className = 'month out ' + (this.next ? 'next' : 'prev');
      setTimeout(() => {
        this.oldMonth.parentNode.removeChild(this.oldMonth);
        this.month = this.createElement('div', 'month');
        this.backFill();
        this.currentMonth();
        this.forwardFill();
        this.calendarContainerRef.nativeElement.appendChild(this.month);
        window.setTimeout(() =>  this.month.className = 'month in ' + (this.next ? 'next' : 'prev'), 160);
      }, 200);
    } else {
      this.month = this.createElement('div', 'month');
      this.calendarContainerRef.nativeElement.appendChild(this.month);
      this.backFill();
      this.currentMonth();
      this.forwardFill();
      this.month.className = 'month in next';
    }
  }

  /**
   * Add preceding days
   */
  backFill() {
    let clone = new Date(this.firstOfMonth);
    let dayOfWeek = clone.getDay();
    if (dayOfWeek) {
      clone.setDate(clone.getDate() - (dayOfWeek + 1));
      for (let i = dayOfWeek; i > 0; i--) {
        clone.setDate(clone.getDate() + 1)
        this.drawDay(clone);
      }
    }
  }

  /**
   * Add trailing days
   */
  forwardFill() {
    let clone = new Date(this.firstOfMonth);
    clone.setMonth(clone.getMonth() + 1);
    clone.setDate(clone.getDate() - 1);
    let dayOfWeek = clone.getDay();

    if (dayOfWeek !== 6) {
      for (let i = dayOfWeek; i < 6; i++) {
        clone.setDate(clone.getDate() + 1);
        this.drawDay(clone);
      }
    }
  }

  /**
   * Loop through and add days for the month
   */
  currentMonth() {
    let clone = new Date(this.firstOfMonth);
    while (clone.getMonth() === this.firstOfMonth.getMonth()) {
      this.drawDay(clone);
      clone.setDate(clone.getDate() + 1);
    }
  }

  /**
   * Create weeks
   * @param day
   */
  getWeek(day) {
    if (!this.week || day.getDay() === 0) {
      this.week = this.createElement('div', 'week');
      this.month.appendChild(this.week);
    }
  }

  /**
   * Draw day elements
   * @param day
   */
  drawDay(day) {
    this.getWeek(day);
    // Outer Day
    let outer = this.createElement('div', this.getDayClass(day));
    outer.addEventListener('click', () => this.openDay(outer));
    // Day Name
    let name = this.createElement('div', 'day-name', this.datePipe.transform(day, 'ccc'));
    // Day Number
    let number = this.createElement('div', 'day-number', this.datePipe.transform(day, 'd'));
    outer.appendChild(name);
    outer.appendChild(number);
    this.week.appendChild(outer);
  }

  /**
   * Draw events for a given day
   * @param element
   */
  drawEvents(element: HTMLElement) {
    let selected = `${this.getMonthName().split(' ')[0]} ${element.querySelector('.day-number').textContent}`;
    let selectedWithYear = `${selected} ${this.getYear()}`; // Added to fix events not working past 2020 (who knew we'd survive this long)
    this.selectedDay = new Date(selectedWithYear);
    this.elementRef.nativeElement.querySelector('.selected-day').textContent = selected;
    this.updateEventList();
  }

  /**
   * Get the type of day
   * @param day
   * @returns {string}
   */
  getDayClass(day): string {
    let classes = ['day'];
    if (day.getMonth() !== this.firstOfMonth.getMonth()) {
      classes.push('other');
    } else if (this.compareDateEquality(this.today, day)) {
      classes.push('today');
    }
    for (let event of this.events) {
      const startTime = event.start_time ? new Date(event.start_time) : null;
      const endTime = event.end_time ? new Date(event.end_time) : null;
      if (this.compareDateEquality(startTime, day) || this.compareDateEquality(endTime, day)) {
        classes.push('day-event');
      }
    }
    return classes.join(' ');
  }

  /**
   * When a day has no events
   * @param el
   */
  openDay(el: HTMLElement) {
    if (el.classList.contains('other')) {
      return;
    }
    const prevSelected = this.elementRef.nativeElement.querySelector('.day.open');
    if (prevSelected) {
      prevSelected.classList.remove('open');
    }
    el.classList.add('open');
    this.drawEvents(el);
  }

  // Get next month
  nextMonth() {
    this.firstOfMonth.setMonth(this.firstOfMonth.getMonth() + 1);
    this.next = true;
    this.drawMonth();
    this.updateEventList();
  }

  // Get previous month
  prevMonth() {
    this.firstOfMonth.setMonth(this.firstOfMonth.getMonth() - 1);
    this.next = false;
    this.drawMonth();
    this.updateEventList();
  }

  getMonthName() {
    return this.datePipe.transform(this.firstOfMonth, 'MMMM')
  }

  getYear() {
    return this.datePipe.transform(this.firstOfMonth, 'YYYY');
  }

  /**
   * Helper function to create elements
   * @param tagName
   * @param className
   * @param innerText
   * @returns {Element}
   */
  createElement(tagName: string, className?: string, innerText?: string) {
    const el = this.renderer.createElement(tagName);
    if (className) {
      el.className = className;
    }
    if (innerText) {
      el.textContent = innerText;
    }
    return el;
  }
}

