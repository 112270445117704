import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html'
})
export class ResourcesComponent {

  @Input() title;
  @Input() children;
  @Input() responsive;

  constructor() { }

}
