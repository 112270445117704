import {Injectable} from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
  }

  public sendAction(
    eventAction: string,
    eventProperties: any) {
    let newProperties = { // Transform for new ga4 implementation
      event_category: eventProperties.category,
      event_label: eventProperties.label
    };
    gtag('event', eventAction, newProperties);
  }

  public sendPageViewEvent(title: string, location: string, path: string) {
    gtag('event', 'page_view', {
      page_title: title,
      page_location: location,
      page_path: path
    });
  }
}
