import {Component, OnInit, ElementRef, ViewChild, AfterViewChecked, ChangeDetectorRef} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'app-flash-poll',
  templateUrl: './flash-poll.component.html',
  styleUrls: ['./flash-poll.component.scss'],
  animations: [
    trigger('castVote', [
      state('options', style({
        width: 0,
        height: '100%',
        backgroundColor: 'rgba(30, 136, 229, 0.6)',
        position: 'absolute',
        zIndex: '-1'
      })),
      state('results', style({
          width: '{{result_width}}',
          height: '100%',
          backgroundColor: 'rgba(30, 136, 229, 0.6)',
          position: 'absolute',
          zIndex: '-1'
        })
        , {params: {result_width: '0px'}}),
      transition('options => results', [
        animate('0.6s')
      ]),
      transition('results => options', [
        animate('0.6s')
      ]),
    ]),
  ]
})

export class FlashPollComponent implements OnInit, AfterViewChecked {
  @ViewChild("optionContainer") optionWidth: ElementRef;

  selected: number = null;
  poll: any = null;
  submitted = false;
  showResults = false;
  animate = false;
  isFullPage = false;

  constructor(private drupalService: DrupalService, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.submitted = false;
    this.drupalService.getPoll().then(response => {
      if(response) {
        this.poll = response;
        this.showResults = this.poll.results.length > 0;
        if (this.poll.has_voted !== false) {
          this.submitted = true;
          this.selected = this.poll.has_voted;
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  vote(index) {
    let voteId = this.poll.options[index]['chid'];
    if (!this.submitted) {
      this.drupalService.castVote(this.poll.pid, voteId).then(response => {
        this.poll = response;
        this.selected = this.poll.has_voted;
        this.submitted = true;
      });
    }
  }

  cancelVote() {
    this.submitted = false;
    this.animate = false;
    this.selected = null;
    this.drupalService.cancelVote(this.poll.pid);
  }

  /**
   * Starts the bar graph animations when the page is loaded or refreshed.
   */
  updateAnimation() {
    if (this.submitted && !this.animate) {
      this.animate = true;
    }
  }

}
