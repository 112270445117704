import {AfterViewInit, OnChanges, SimpleChanges, Renderer2, Component, Input, ViewChild, ElementRef } from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html'
})
export class MediaComponent implements AfterViewInit, OnChanges {
  @Input() mediaId;
  @ViewChild('videoContainer', { static: true }) videoContainerRef: ElementRef;

  public type;
  public title;
  public resource_url = "";
  public mediaSuccess = true;

  constructor(private drupalService: DrupalService, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.mediaId.isFirstChange()) {
      this.loadData();
    }
  }

  /**
   * Resolve current page promise and fetch any associated media
   */
  async loadData() {
    if (isNaN(this.mediaId)) {
      this.mediaSuccess = false;
      return;
    }

    try {
      this.drupalService.getMedia(this.mediaId).then(media => {
        this.title = media[0].name;
        this.handleMedia(media[0]);
      });
    } catch {
      this.mediaSuccess = false;
    }
  }

  /**
   * Display a bit of media based on type
   * @param media
   */
  handleMedia(media) {
    if (media.field_media_file.toLowerCase().indexOf('pdf') !== -1) {
      this.type = 'pdf';
      this.displayPDF(media.field_media_file);
    } else if (media.video_url !== '') {
      this.type = 'video';
      this.displayVideo(media.video_url);
    } else if (media.field_media_image !== '') {
      this.type = 'image';
      this.displayImage(media);
    } else {
      this.type = 'file';
      this.resource_url = window['DRUPAL_URL_FILE'] + media.field_media_file;
    }
  }

  // Set image url to display
  displayImage(media) {
    this.resource_url = window['DRUPAL_URL_FILE'] + media.field_media_image;
  }


  // Embed the PDF with the given url
  displayPDF(url) {
    this.resource_url = window['DRUPAL_URL_FILE'] + url;
  }

  // Add an iframe to display the given vimeo url
  displayVideo(url) {
    let video_bits = url.split('/');
    this.resource_url = video_bits[video_bits.length - 1];
    const videoEl = this.renderer.createElement('iframe');
    videoEl.src = `https://player.vimeo.com/video/${this.resource_url}`;
    this.renderer.setAttribute(videoEl, 'webkitallowfullscreen', 'true');
    this.renderer.setAttribute(videoEl, 'mozallowfullscreen', 'true');
    this.renderer.setAttribute(videoEl, 'allowfullscreen', 'true');
    this.renderer.appendChild(this.videoContainerRef.nativeElement, videoEl);
  }
}
