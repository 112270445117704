<div>
  <div class="media-failed" *ngIf="!mediaSuccess">Failed to load attached media.</div>
  <a href="{{resource_url}}" target="_blank" class="fullpage-link" *ngIf="type === 'pdf'"
     [gaEvent]="{category: 'PDF Media', action: 'click', label: 'Fullscreen'}">Click here to view fullpage</a>
  <div class="pdf" *ngIf="type === 'pdf'">
    <ng-template #customFindbarInputArea>
      <div id="findbarInputContainer">
        <pdf-search-input-field></pdf-search-input-field>
        <br />
        <pdf-find-previous></pdf-find-previous>
        <pdf-find-next></pdf-find-next>
      </div>
    </ng-template>

    <ng-template #customFindbarButtons>
      <pdf-find-input-area [customFindbarInputArea]="customFindbarInputArea"></pdf-find-input-area>
      <div id="findbarOptionsOneContainer">
        <pdf-find-highlight-all></pdf-find-highlight-all>
        <pdf-find-match-case></pdf-find-match-case>
      </div>
      <br />
      <div id="findbarOptionsTwoContainer">
        <pdf-find-entire-word></pdf-find-entire-word>
        <pdf-find-entire-phrase></pdf-find-entire-phrase>
      </div>
      <br />
      <div id="findbarOptionsThreeContainer">
        <pdf-find-ignore-accents></pdf-find-ignore-accents>
        <pdf-find-results-count></pdf-find-results-count>
      </div>
      <br />
      <pdf-findbar-message-container></pdf-findbar-message-container>
    </ng-template>
    <ngx-extended-pdf-viewer [src]="resource_url"
                             [authorization]="true"
                             [customFindbarInputArea]="customFindbarInputArea"
                             [customFindbarButtons]="customFindbarButtons"
                             [showOpenFileButton]="false"
                             [textLayer]="true"
                             [theme]="'dark'"
                             [zoom]="'page-fit'"
                             [height]="'90vh'"
                             [backgroundColor]="'#525659'"
                             [showSecondaryToolbarButton]="false"
                             [showPresentationModeButton]="true">
    </ngx-extended-pdf-viewer>
  </div>
  <div class='video' *ngIf="type === 'video'">
    <div #videoContainer class="video-holder"></div>
  </div>
  <div class="media-image" *ngIf="type === 'image'">
    <img src="{{resource_url}}">
  </div>
  <div class="file-holder" *ngIf="type === 'file'">
    <h4>Click below to download</h4>
    <div class="file">
      <a href="{{resource_url}}" download class="download-link"
         [gaEvent]="{category: 'Download Link', action: 'click', label: title}"><i class="fas fa-file-word"></i><span
        class="title">{{title}}</span></a>
    </div>
  </div>
</div>



