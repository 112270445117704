import {Injectable, OnInit} from '@angular/core';
import {Quicklink} from "../../object/quicklink";
import {DrupalService} from "../drupal/drupal.service";
import {Subject} from "rxjs";

@Injectable()
export class QuickLinkService {

  initLinksPromise;
  QUICK_LINKS_TAG = "quicklinks";
  private quicklinkSubject = new Subject<Array<Quicklink>>();
  quickLinkStream = this.quicklinkSubject.asObservable();
  private quickLinks: Quicklink[] = [];

  constructor(private drupalService: DrupalService) {
    this.initLinks();
  }

  async initLinks() {
    this.initLinksPromise = new Promise(async resolve => {
      let links: any = [];
      if (window.localStorage.getItem(this.QUICK_LINKS_TAG)) {
        const stored = JSON.parse(window.localStorage.getItem(this.QUICK_LINKS_TAG));
        links = await this.verifyLinks(stored);
      }
      this.quickLinks = links;
      this.quicklinkSubject.next(this.quickLinks);
      resolve(this.quickLinks);
    })
  }

  /**
   * Verifies that all saved links are still valid
   * @returns {Promise<void>}
   */
  async verifyLinks(links) {
    let finalLinks: Quicklink[] = [];
    const promises = [];

    for (let i = 0; i < links.length; i++) {
      if (!links[i].nid) {
        links[i].nid = parseInt(links[i].href.split('/').pop());
      }
      const promise = this.checkLink(links[i].nid).then(response => {
        if (response && response[0].title === links[i].title) finalLinks[i] = links[i];
      });
      promises.push(promise);
    }

    await Promise.all(promises);

    return Object.values(finalLinks);
  }

  async getQuickLinks() {
    await this.initLinksPromise;
    return this.quickLinks;
  }

  /**
   * Helper method to look up node to check that it still exists
   * @param nid
   * @returns {Promise<Array<any>>}
   */
  checkLink(nid) {
    return this.drupalService.checkNodeExists(nid).then(response => response.length > 0 ? response : false);
  }

  /**
   * Saves links to service and to local storage
   * @param links
   */
  saveLinks(links) {
    this.quickLinks = links;
    window.localStorage.setItem(this.QUICK_LINKS_TAG, JSON.stringify(this.quickLinks));
    this.quicklinkSubject.next(links);
  }

  /**
   * Adds a new link if it is not already present and saves
   * @param {Quicklink} newLink
   */
  addLink(newLink: Quicklink) {
    let isPresent = false;
    for (let link of this.quickLinks) {
      if (link.title === newLink.title) {
        isPresent = true;
      }
    }
    if (!isPresent) {
      this.quickLinks.push(newLink);
      this.saveLinks(this.quickLinks);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Removes a quick link by a given title
   * @param title
   */
  removeLink(title) {
    let index = 0;
    for (let link of this.quickLinks) {
      if (link.title === title) {
        this.quickLinks.splice(index, 1);
        break;
      }
      index++
    }
    this.saveLinks(this.quickLinks);
  }

  /**
   * Returns true if there is a quicklink present with the given name
   * @param title
   * @returns {boolean}
   */
  async findLink(title) {
    await this.initLinksPromise;
    return this.quickLinks.some(link => link.title === title);
  }
}


