<div (clickOutside)="clickOutsideDropdown($event)">
  <header class="rw-header">
    <div class="rw-header-upper">

      <!-- Top nav -->
      <div class="rw-header-row top">
        <img class="rw-logo rw-header-item" id="rwLogo" src="/assets/svg/reynoldsworld_logo_white.svg"
             (click)="goHome()">
        <div class="rw-loc-dropdown rw-header-item">
          <dropdown [id]="'location-dropdown'" [title]="getCurrentLocation()" [options]="locOptions"
                    (selectOption)="changeLocation($event)"></dropdown>
        </div>

        <!-- Responsive top nav hidden by default -->
        <div class="responsive-menu-container rw-header-item"
             [gaEvent]="{category: 'Responsive Main Nav', action: 'click', label: 'Sub Nav Toggle'}"
             (click)="toggleResponsiveMenu()" *ngIf="smallHeaderSize">
        <span>
          <button class="responsive-header-menu-icon" [ngClass]="{'open' : responsiveDropdownOpen}"></button>
          <div class="source-sans">MENU</div>
        </span>
        </div>
      </div>

      <!-- Top nav links -->
      <div class="rw-header-row bottom" *ngIf="!smallHeaderSize">
        <div class="rw-menu" *ngFor="let landing of landings" (click)="toggleHeaderMenu($event, landing)"
             [ngClass]="{'open' : landing.open}"
             [gaEvent]="{category: 'Main Nav', action: 'click', label: landing.name}">
          <span [innerHTML]="landing.name | uppercase"></span><span class="dropdown_arrow"></span>
        </div>
        <ng-container *ngIf="showManagementTab && landings.length">
          <a class="rw-menu" [href]="managementResourcesUrl" target="_blank"
               [gaEvent]="{category: 'Main Nav', action: 'click', label: 'Management'}">
            <span>MANAGEMENT</span><i class="fa fa-external-link"></i>
          </a>
        </ng-container>
        <div class="rw-searchbar-container">
          <input type="text" (keydown)="onKeydown($event)" class="rw-header-search header-search"
                 placeholder="Search ..." [(ngModel)]="query">
          <button class="rw-search-btn" (click)="search()"
                  [gaEvent]="{category: 'Header Search', action: 'search', label: query}"></button>
        </div>
      </div>
    </div>
  </header>

  <!-- Sub nav -->
  <div class="rw-header-lower large" [ngClass]='{"open" : headerDropdownOpen}' *ngIf="!smallHeaderSize">
    <div class="lower-category" *ngFor="let category of currentSubheaders">
      <div class="lower-title" *ngIf="category.name"
           (click)="navigateToChild(category.landing)">{{ category.name }}
      </div>
      <ul class="lower-options">
        <li class="lower-option" *ngFor="let child of category.pages" [innerHTML]="child.title"
            [gaEvent]="{category: 'Sub Nav', action: 'click', label: child.title}"
            (click)="navigateToChild(child)"></li>
      </ul>
    </div>
  </div>

  <!-- Responsive top nav links hidden by default -->
  <div class="header-responsive-lower" [ngClass]="{'open' : responsiveDropdownOpen}" *ngIf="smallHeaderSize">
    <div class="rw-menu-holder">
      <div *ngFor="let landing of landings">
        <div class="rw-menu" (click)="toggleHeaderMenu($event, landing)"
             [gaEvent]="{category: 'Main Nav', action: 'click', label: landing.name}">
          <span [innerHTML]="landing.name | uppercase"></span><span class="dropdown_arrow"></span>
        </div>
        <div class="rw-header-lower" [ngClass]="{'open' : landing.open}">
          <div class="lower-category" *ngFor="let category of currentSubheaders">
            <div class="lower-title" *ngIf="category.name"
                 (click)="navigateToChild(category.landing)">{{ category.name }}
            </div>
            <ul class="lower-options">
              <li class="lower-option" *ngFor="let child of category.pages" [innerHTML]="child.title"
                  [gaEvent]="{category: 'Sub Nav', action: 'click', label: child.title}"
                  (click)="navigateToChild(child)"></li>
            </ul>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showManagementTab">
        <a class="rw-menu" [href]="managementResourcesUrl" target="_blank"
           [gaEvent]="{category: 'Main Nav', action: 'click', label: 'Management'}">
          <span>MANAGEMENT</span><i class="fa fa-external-link"></i>
        </a>
      </ng-container>
    </div>

    <!-- Responsive sub nav hidden by default -->
    <div class="rw-menu-holder-lower">
      <div class="rw-searchbar-container">
        <input type="text" (keydown)="onKeydown($event)" class="rw-header-search header-search"
               placeholder="Search ..." [(ngModel)]="query">
        <button class="rw-search-btn" (click)="search()"
                [gaEvent]="{category: 'Header Search', action: 'search', label: query}"></button>
      </div>
      <!--<div class="rw-loc-dropdown rw-header-item" (click)="toggleMobileLocationDropdown()" (clickOutside)="closeMobileLocationDropdown($event)"><span [innerHTML]="getCurrentLocation()"></span><span class="location_arrow"></span>-->
      <!--<ul class="rw-loc-menu mobile" [ngClass]="{'open' : mobileLocDropdownOpen}">-->
      <!--<li *ngFor="let loc of getLocations()" (click)="changeLocation(loc)" [innerHTML]="loc" [gaEvent]="{category: 'Location Dropdown', action: 'choose', label: loc}"></li>-->
      <!--</ul>-->
      <!--</div>-->
      <div class="rw-loc-wrapper">
        <div class="rw-loc-dropdown rw-header-item">
          <dropdown [id]="'location-dropdown-mobile'" [title]="getCurrentLocation()"
                    (selectOption)="changeLocation($event)" [options]="locOptions"></dropdown>
        </div>
      </div>
    </div>
  </div>

</div>
