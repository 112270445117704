import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import {DrupalService} from "../service/drupal/drupal.service";
import {QuickLinkService} from "../service/quick-link/quick-link.service";
import {SearchService} from "../service/search/search.service";
import {LocationService} from "../service/location/location.service";
import {RouteResolverService} from "../service/route-resolver/route-resolver.service";
import {FormService} from "../service/form/form.service";
import {RWCommonModule} from "../common/RW-common.module";
import {AuthModule} from "../auth/auth.module";
import {RouterModule} from "@angular/router";
import {CanLoadSiteService} from "../service/can-load-site/can-load-site.service";
import {AppRoutingModule} from "./app-routing.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthService} from "../service/auth/auth.service";
import {TokenInterceptor} from "../interceptor/token.interceptor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {DatePipe} from "@angular/common";
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    RWCommonModule,
    BrowserAnimationsModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    DrupalService,
    RouteResolverService,
    SearchService,
    AuthService,
    FormService,
    CanLoadSiteService,
    QuickLinkService,
    LocationService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
