import {Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {DatePipe} from "@angular/common";

declare const gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'ReynoldsWorld';

  constructor(private router: Router, private datePipe: DatePipe) {
    gtag('js', new Date());
    gtag('config', window["ANALYTICS_ID"], { 'send_page_view': false, debug_mode: !window['production'] });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.indexOf("css-auth-validation") > -1) {
          return
        }
        gtag('event', 'virtual_page_view');
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigateByUrl(event.url.replace('/#', ''));
        }

      } else if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    // Get date for print formatting
    const html = document.getElementsByTagName('html')[0];
    const date = this.datePipe.transform(new Date(), 'MMM dd YYYY');
    const year = this.datePipe.transform(new Date(), 'YYYY');
    html.style.setProperty("--current-date-full", "'" + date + "'");
    html.style.setProperty("--current-year", "'" + year + "'");
  }
}
