import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";
import {ActivatedRoute, ActivationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  @Input() page;

  pageID;
  breadcrumbs = [];
  title = '';
  loading = false;
  routeSub;
  crumbCache = {};

  constructor(
      private drupalService: DrupalService,
      private route: ActivatedRoute
  ) { }

    /**
     * Get breadcrumb trail
     */
    ngOnInit() {
        this.routeSub = this.route.url.subscribe(url => {
            setTimeout(() => {
                this.routeChange();
            }, 100);
        });
    }

    ngOnDestroy(){
        this.routeSub.unsubscribe();
        this.breadcrumbs = undefined;
    }

    /**
     * Route change event that is called to get a new set of breadcrumbs.
     * If the current page is an article, it will use the articleCrumbHelper, otherwise it uses the normal helper.
     */
    async routeChange(){
        this.loading = true;
        this.breadcrumbs = [];
        this.crumbCache = {};
        if (this.page){
            this.pageID = this.page.nid;
            this.crumbCache[this.pageID] = this.page;
            this.title = this.page.title;
            if (this.page.field_parent !== ""){
                this.crumbHelper(this.page.field_parent);
            } else if (this.page.field_article_type !== "" && this.page.type.toLowerCase() === 'reynoldsworld article'){
                this.articleCrumbHelper(this.page.field_article_type);
            } else {
                this.loading = false;
            }
        } else if (this.route.url.toString().toLowerCase().indexOf("Search")) {
            this.pageID = -1;
            this.title = 'Search';
            this.loading = false;
        } else {
            this.loading = false;
        }
    }

    /**
     * Helper function to get a breadcrumb for an article.
     * If it has a parent, call the normal breadcrumb helper. We do not call ourselves because an article cannot be
     * a child of another article.
     * @param articleType
     */
    async articleCrumbHelper(articleType){
        let data = (await this.drupalService.getBlogHomepage(articleType))[0];
        // Check if we've already seen this page-- if we have then there is an infinite loop we need to stop
        if (data && this.crumbCache[data.nid]){
            this.loading = false;
        } else if (data) {
            this.breadcrumbs.unshift(data);
            this.crumbCache[data.nid] = data;
            if (data.field_parent !== "") {
                this.crumbHelper(data.field_parent);
            } else {
                this.loading = false;
            }
        }
    }

    /**
     * Helper function to get a breadcrumb for a page. Pages may never be children of articles.
     * @param id
     */
    async crumbHelper(id){
        let data = (await this.drupalService.getNode(id))[0];
        if (data && this.crumbCache[data.nid]) {
            this.loading = false;
        } else if (data) {
            this.breadcrumbs.unshift(data);
            this.crumbCache[data.nid] = data;
            if (data.field_parent !== "") {
                this.crumbHelper(data.field_parent);
            } else {
                this.loading = false;
            }
        }
    }

}
