import { Component } from '@angular/core';

@Component({
    selector: 'app-calendar-month',
    templateUrl: './calendar-month.component.html'
})
export class CalendarMonthComponent {

  constructor() { }

}
