import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from "./home/home.component";
import {HeaderComponent} from "./header/header.component";
import {FooterComponent} from "./footer/footer.component";
import {ArticleComponent} from "./article/article.component";
import {BreadcrumbsComponent} from "./breadcrumbs/breadcrumbs.component";
import {SearchResultsComponent} from "./search-results/search-results.component";
import {SidebarComponent} from "./sidebar/sidebar.component";
import {CalendarMonthComponent} from "./calendar-month/calendar-month.component";
import {ArchiveComponent} from "./archive/archive.component";
import {MediaComponent} from "./media/media.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {ClickOutsideDirective} from "../directive/click-outside/click-outside.directive";
import {ResourcesComponent} from "./resources/resources.component";
import {DropdownComponent} from "./dropdown/dropdown.component";
import {FormModule} from "../form/form.module";
import {FormsModule} from "@angular/forms";
import {WidgetModule} from "../widget/widget.module";
import {CommonComponent} from './common.component';
import {RWCommonRoutingModule} from "./RW-common-routing.module";
import {RouterModule} from "@angular/router";
import {HistoricalPollsComponent} from './historical-polls/historical-polls.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {ArchiveFiltersComponent} from "./archive/archive-filters/archive-filters.component";


@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ArticleComponent,
    BreadcrumbsComponent,
    SearchResultsComponent,
    SidebarComponent,
    CalendarMonthComponent,
    ArchiveComponent,
    MediaComponent,
    NotFoundComponent,
    ClickOutsideDirective,
    ResourcesComponent,
    DropdownComponent,
    CommonComponent,
    HistoricalPollsComponent
  ],
  imports: [
    CommonModule,
    WidgetModule,
    FormsModule,
    RouterModule,
    RWCommonRoutingModule,
    FormModule,
    NgxExtendedPdfViewerModule,
    ArchiveFiltersComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})
export class RWCommonModule {
}
