import {Component, OnInit} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";

@Component({
  selector: 'app-current-news',
  templateUrl: './current-news.component.html',
  styleUrls: ['./current-news.component.scss']
})
export class CurrentNewsComponent implements OnInit {

  public pages = []; // Our currently displayed news pages

  constructor(
    private drupalService: DrupalService
  ) {
  }

  /**
   * On init, get current news pages
   */
  ngOnInit() {
    this.drupalService.getArticlePreview(this.getCurrentNewsLabel()).then(response => {
      let topSticky = null;
      for (let i = 0; i < response.length; i++) {
        let page = response[i];
        if (!page) {
          continue;
        }

        let isStickied = page.field_sticky.toLowerCase() === 'on';

        if (page.news_tag !== '') {
          page.news_tag = page.news_tag.replace(/&#039;/g, "\'")
        }

        if (!isStickied || (isStickied && topSticky)) {
          this.pages.push(page);
        } else { // Don't display the most recent stickied item since this will be displayed at the top of the page
          topSticky = page;
        }

        if (this.pages.length === this.getMaxCurrentNewsItems()) {
          break;
        }
      }
    });
  }

  getCurrentNewsLabel() {
    return window['CURRENT_NEWS_LABEL'];
  }

  getNumStandins() {
    return window['NUM_STANDINS'];
  }

  getDrupalImageURL() {
    return window['DRUPAL_IMAGE_URL'];
  }

  getMaxCurrentNewsItems() {
    return window['NUM_CURRENT_NEWS_ITEMS'];
  }
}
