import {Component, ViewChild, ElementRef, OnInit} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";

@Component({
  selector: 'app-jumbo',
  templateUrl: './jumbo.component.html',
  styleUrls: ['./jumbo.component.scss']
})
export class JumboComponent implements OnInit {

  public stickyPage;
  public isVideo = false;

  constructor(
    private drupalService: DrupalService
  ) {
  }

  ngOnInit() {
    let filter = [{
      term: 'sticky',
      values: ['1']
    }];
    this.drupalService.getArticlePreview(false, filter).then(response => {
      this.stickyPage = response[0];
      if (this.stickyPage.news_tag) {
        this.stickyPage['news_tag'] = this.stickyPage.news_tag.replace(/&#039;/g, "\'");
      }
    });
  }

  getDrupalImageUrl() {
    return window['DRUPAL_IMAGE_URL'];
  }

}
