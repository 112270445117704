import {Component, ViewChild, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Quicklink} from "../../object/quicklink";
import {QuickLinkService} from "../../service/quick-link/quick-link.service";
import {MediaComponent} from "../media/media.component";
import {FormComponent} from "../../form/form/form.component";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html'
})
export class ArticleComponent implements OnInit, OnDestroy {

  @ViewChild(MediaComponent, {static: false}) mediaComponent: MediaComponent;
  @ViewChild(FormComponent, {static: false}) formComponent: FormComponent;

  public pageLoaded: any;
  public children;
  public isQuickLink = false;
  public responsive = false;
  public inline: boolean;
  private quickLinkSub;
  private routeSub;

  constructor(
    private drupalService: DrupalService,
    private route: ActivatedRoute,
    private router: Router,
    private quickLinkService: QuickLinkService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.adjustScreenSize(window);
    this.routeSub = this.route.data.subscribe(async data => {
      if (!data.pageData) {
        this.router.navigateByUrl('not_found');
      }
      await this.resolvePage(data.pageData);
      this.isQuickLinked();
      this.quickLinkSub = this.quickLinkService.quickLinkStream.subscribe(() => this.isQuickLinked());
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.quickLinkSub.unsubscribe();
    this.children = undefined;
  }

  /**
   * Determines if the current page is a quicklink
   */
  private async isQuickLinked() {
    this.quickLinkService.findLink(this.pageLoaded.title).then(result => this.isQuickLink = result)
  }

  // Evaluates screen size to determine which home layout to show
  adjustScreenSize(window) {
    this.responsive = (window.innerWidth <= window['MAX_LARGE_HOME_SIZE']);
  }

  /**
   * Sends a request to add or remove the current page as a quicklink and animates the button
   */
  toggleQuickLink() {
    if (this.isQuickLink) {
      this.quickLinkService.removeLink(this.pageLoaded.title);
      this.isQuickLink = false;
      return;
    }

    const isArchive = this.pageLoaded.field_homepage === "On";
    const isAliased = this.pageLoaded.path && !this.pageLoaded.path.includes('/node/');
    const newQuickLink: Quicklink = {
      nid: this.pageLoaded.nid,
      title: this.pageLoaded.title,
      href: `/article/${this.pageLoaded.nid}`,
      icon: 'quicklink.svg',
    };

    if (isArchive) {
      newQuickLink.href = `/archive/${this.pageLoaded.field_article_type}`;
    } else if (isAliased) {
      newQuickLink.href = this.pageLoaded.path;
    }

    this.isQuickLink = true;
    this.quickLinkService.addLink(newQuickLink);
  }

  /**
   * Get page for node.
   * Here we use pageLoaded instead of using page with the async pipe so that some pre-processing can be done
   * on the body to correct the images.
   */
  private async resolvePage(pageData) {
    this.pageLoaded = await this.fixPageData(pageData);
    this.children = await this.drupalService.getChildren(this.pageLoaded.resource_sort, this.pageLoaded.nid);
  }

  private fixPageData(pageData) {
    return new Promise(resolve => {
      pageData.body = this.fixMedia(pageData.body);
      pageData.body = this.fixDrupalLinks(pageData.body);
      pageData.body = this.sanitizer.bypassSecurityTrustHtml(pageData.body);
      pageData.news_tag = this.fixTag(pageData);
      if (pageData.field_group) {
        this.drupalService.getGroupEmail(pageData.field_group).then(response => pageData.email = response[0]['field_email']);
      }
      resolve(pageData);
    });
  }

  /**
   * Formats the tag if there is one
   * @param fetched
   * @returns {any}
   */
  private fixTag(fetched) {
    if (!fetched.news_tag) {
      return undefined;
    }
    return fetched.news_tag = fetched.news_tag.replace(/&#039;/g, "\'");
  }

  fixDrupalLinks(body) {
    return body.replace(/"\/sites\/default\/files/g, "\"" + window['DRUPAL_URL_FILE'] + "/sites/default/files");
  }

  // Replace image sources with appropriate drupal location
  fixMedia(body: string) {
    const div = this.renderer.createElement('div');
    div.innerHTML = body;

    const imgElements = div.querySelectorAll('img');
    const frameElements = div.querySelectorAll('iframe');
    const inlineElements = div.querySelectorAll('td img');
    const tdElements = div.querySelectorAll('td');

    imgElements.forEach((img: any) => {
      this.renderer.setAttribute(img, 'src', window['DRUPAL_IMAGE_URL'] + img.getAttribute('src'));
      this.renderer.addClass(img, 'drupal-image');
      this.renderer.removeAttribute(img, 'height');
      this.renderer.setStyle(img, 'max-width', '100%');
      this.renderer.setStyle(img, 'height', 'auto');
    });
    frameElements.forEach((frame: any) => {
      this.renderer.addClass(frame, 'drupal-frame');
      this.renderer.setStyle(frame, 'max-width', '100%');
    });
    inlineElements.forEach((inline: any) => {
      this.renderer.addClass(inline, 'drupal-inline');
      this.renderer.removeStyle(inline, 'max-width');
    });
    tdElements.forEach((td: any) => {
      this.renderer.addClass(td, 'drupal-td');
    });

    return div.innerHTML;
  }

  getDrupalImageUrl() {
    return window['DRUPAL_IMAGE_URL'];
  }
}
