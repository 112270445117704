import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";
import {LocationService} from "../../service/location/location.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-food-menu',
  templateUrl: './food-menu.component.html',
  styleUrls: ['./food-menu.component.scss']
})

export class FoodMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('menuEl', {static: true}) menuElRef: ElementRef;
  @ViewChild('foodMenu', {static: true}) foodMenuRef: ElementRef;

  public dayMenu = [];
  public menuDate;
  public currentDay;
  public currentMenuIndex;
  public currentMenus = Array(2);
  public locationName = '';
  public loading = true;
  public facilityHours;
  public menuCardWidth = 0;
  private noMenuLocs = ['canada', 'united kingdom'];
  private dayMap = {
    '1': 'mon',
    '2': 'tue',
    '3': 'wed',
    '4': 'thu',
    '5': 'fri'
  }

  private locationSub;

  constructor(
    private drupalService: DrupalService,
    private locationService: LocationService,
    private elementRef: ElementRef,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.currentDay = new Date();
    this.getMenu(this.locationService.getLocation());
    this.locationSub = this.locationService.locationStream.subscribe(loc => this.getMenu(loc));
  }

  ngAfterViewInit() {
    this.menuCardWidth = this.menuElRef.nativeElement.offsetWidth;
  }

  ngOnDestroy() {
    this.locationSub.unsubscribe();
  }

  onResize() {
    this.menuCardWidth = this.menuElRef.nativeElement.offsetWidth;
  }

  /**
   * Get location and current and next week's menu by location
   * @param loc
   */
  async getMenu(loc: string) {
    const hasMenu = loc && !this.noMenuLocs.includes(loc.toLowerCase());
    this.elementRef.nativeElement.hidden = !hasMenu;
    if (!hasMenu) {
      this.loading = false;
      return;
    }

    this.loading = true;

    this.facilityHours = await this.locationService.getFacilityHours();
    this.setCafeName(loc);

    await this.getTwoWeeksMenu();

    if (this.currentMenus[0]) {
      this.currentMenuIndex = 0;
      this.getTodaysMenu('next');
    }
    this.loading = false;
  }

  /**
   * Get the menu for the current week and upcoming week, if it exists.
   * Return when both menus have resolved.
   * @returns {Promise<[any]>}
   */
  async getTwoWeeksMenu(): Promise<void> {
    this.dayMenu = [];

    // Get this week's menu
    const week = new Date();
    week.setDate(week.getDate() - (week.getDay() + 6) % 7);
    // Get next week's menu
    const nextWeek = new Date(week);
    nextWeek.setDate(week.getDate() + 7);

    this.currentMenus[0] = await this.getMenuByWeek(this.datePipe.transform(week, 'YYYY-MM-dd'));
    this.currentMenus[1] = await this.getMenuByWeek(this.datePipe.transform(nextWeek, 'YYYY-MM-dd'));
  }

  private async getMenuByWeek(week): Promise<any[]> {
    const loc = await this.locationService.getLocationTid();
    return await this.drupalService.getMenu(loc, week);
  }

  /**
   * Helper method to set the cafe name
   * @param loc
   */
  setCafeName(loc: string) {
    switch (loc.toLowerCase()) {
      case 'dayton':
        this.locationName = 'Dayton Victory Lane Café';
        break;
      default:
        this.locationName = `${loc} Café`;
        break;
    }
  }

  /**
   * Determines if the next day can be loaded and adjusts the date and menu accordingly
   */
  goForward() {
    if (this.currentDay.getDay() < 5) {
      this.updateCurrentDay(1);
      this.getTodaysMenu('next');
    } else if (this.currentDay.getDay() === 5 && this.currentMenuIndex === 0 && this.currentMenus[1]) {
      this.currentMenuIndex = 1;
      this.updateCurrentDay(3);
      this.getTodaysMenu('next');
    }
  }

  /**
   * Determines if the previous day can be loaded and adjusts the date and menu accordingly
   */
  goBack() {
    if (this.currentDay.getDay() > 1) {
      this.updateCurrentDay(-1);
      this.getTodaysMenu('prev');
    } else if (this.currentDay.getDay() === 1 && this.currentMenuIndex === 1) {
      this.currentMenuIndex = 0;
      this.updateCurrentDay(-3);
      this.getTodaysMenu('prev');
    }
  }

  updateCurrentDay(days: number) {
    this.currentDay.setDate(this.currentDay.getDate() + days);
  }

  /**
   * Gets the menu for the currently selected day of the current menu and formats it for display
   * @param direction
   */
  getTodaysMenu(direction) {
    const currentMenu = this.currentMenus[this.currentMenuIndex];
    if (!currentMenu) {
      return;
    }
    // Get menu for current day of current menu
    const menu = this.foodMenuRef.nativeElement;
    menu.className = 'menu-list out ' + direction;
    setTimeout(() => {
      menu.className = 'menu-list in ' + direction;
      const dayIndex = this.dayMap[this.currentDay.getDay()];
      this.dayMenu = currentMenu['menu'][dayIndex];
      this.menuDate = this.datePipe.transform(this.currentDay, 'EEEE, M/d');
    }, 180);
  }
}
