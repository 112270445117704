import {OnInit, AfterViewChecked, HostListener, Component} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";

@Component({
  selector: 'app-historical-polls',
  templateUrl: './historical-polls.component.html',
  styleUrls: ['./historical-polls.component.scss'],
})
export class HistoricalPollsComponent implements OnInit, AfterViewChecked {

  polls = [];
  page = 0;
  workingStart = '';
  workingEnd = '';
  start = null;
  end = null;
  query = null;
  workingQuery = '';
  pageSize = 20; //This value is defined separately on the backend. On my todo list to fix
  loading = true;
  reachedBottom = false;
  showFilters = false;
  today = null;
  timezone = 'America/New_York';

  constructor(
    private drupalService: DrupalService
  ) {
  }

  ngOnInit(): void {
    this.getPolls();
    if (this.polls.length) {
      this.timezone = this.polls[0].timezone['timezone'];
    }
  }

  ngAfterViewChecked() {
    if (this.showFilters && this.today === null) {
      this.today = new Date().toISOString().split("T")[0];
      document.getElementById("dateRangeEnd").setAttribute("max", this.today);
      let curMax = new Date(document.getElementById('dateRangeStart').getAttribute('max'));
      if(curMax > new Date()) {
        document.getElementById("dateRangeStart").setAttribute("max", this.today);
      }
    } else {
      this.today = null;
    }
  }

  /**
   * Retrieves one page of polls with applied filters.
   */
  getPolls() {
    this.loading = true;
    this.drupalService.getPastPolls(this.page, this.start, this.end, this.query).then(response => {
      if (response.length) {
        this.polls = this.polls.concat(response);
      } else {
        this.reachedBottom = true;
      }
      this.loading = false;
    });
  }

  /**
   * Listens for scroll events to load more results when the bottom of the page is reached
   */
  @HostListener('document:scroll') onScroll() {
    if (!this.reachedBottom && (window.scrollY + window.innerHeight === document.documentElement.scrollHeight)) {
      this.page++;
      this.getPolls();
    }
  }

  /**
   * Resets current page retrieves polls with the new query
   */
  filterPolls() {
    this.page = 0;
    this.polls = [];
    if (this.workingQuery.trim()) {
      this.query = this.workingQuery.trim();
    } else {
      this.query = null;
    }
    this.getPolls();
  }

  /**
   * Resets search filter
   */
  resetSearch() {
    this.page = 0;
    this.workingQuery = '';
    if (this.query) {
      this.query = null;
      this.polls = [];
      this.getPolls();
    }
  }

  validateDate() {
    if (!this.workingStart.trim()) {
      this.start = null;
      document.getElementById("dateRangeEnd").setAttribute("min", '2020-01-01');
    } else {
      this.start = new Date(this.workingStart + ' 00:00:00');
      let min = this.start.toISOString().split("T")[0];
      document.getElementById("dateRangeEnd").setAttribute("min", min);
      this.start = new Date(this.start.toLocaleString("en-US", {timeZone: this.timezone}));
      this.start /= 1000;
    }
    if (!this.workingEnd.trim()) {
      this.end = null;
    } else {
      this.end = new Date(this.workingEnd + ' 23:59:59');
      let max = new Date(this.workingEnd).toISOString().split("T")[0];
      document.getElementById("dateRangeStart").setAttribute("max", max);
      this.end = new Date(this.end.toLocaleString("en-US", {timeZone: this.timezone}));
      this.end /= 1000;
    }
    this.filterPolls();
  }
}
