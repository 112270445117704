import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form/form.component';
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [FormComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  exports: [
      FormComponent
  ]
})
export class FormModule { }
