<section class="card">
    <div class="header-3">My Quick Links</div>
    <nav class="card-body">
        <ul class="quick-links static-links">
            <li *ngFor="let quicklink of getStaticLinks()" class="quicklink-item">
                <img class="quick-link-icon" src="/assets/svg/{{quicklink.icon}}">
                <a href="{{quicklink.href}}" [ngStyle]="{'color': quicklink.title === 'Emergencies' ? 'red' : ''}"
                   [innerHTML]="quicklink.title"
                   [gaEvent]="{category: 'Quicklinks Widget', action: 'click', label: quicklink.title}"></a>
            </li>
        </ul>
        <ul #customLinks class="quick-links custom-links" [ngStyle]="{'display': quicklinks.length === 0 ? 'none' : 'block'}">
            <li *ngFor="let quicklink of quicklinks; let i = index" class="quicklink-item"
                [ngClass]="{'rearrange' : editing, 'disable-sort' : !editing}"
                (mousedown)="reorderLinks($event)" [attr.data-index]="i">
                <span class="remove-icon" (click)="removeQuicklink($event, quicklink.title)"></span>
                <div style="display: flex">
                    <img class="quick-link-icon" src="/assets/svg/{{quicklink.icon}}" draggable="false">
                    <a href="{{quicklink.href}}"  draggable="false"
                       [gaEvent]="{category: 'Quicklinks Widget', action: 'click', label: quicklink.title}">
                        {{ quicklink.title }}
                    </a>
                    <span class="fa fa-bars rearrange-quicklink-icon"></span>
                </div>
            </li>
        </ul>
    </nav>
    <div style="text-align: right" *ngIf="quicklinks.length > 0">
        <button class="btn btn-grey" (click)="editing = true" *ngIf="!editing && quicklinks.length > 0"
                [gaEvent]="{category: 'Quicklinks Widget', action: 'click', label: 'Edit'}"><span>Edit</span></button>
        <button class="btn btn-grey" (click)="apply()" *ngIf="editing"
                [gaEvent]="{category: 'Quicklinks Widget', action: 'click', label: 'Apply'}"><span>Apply</span></button>
    </div>
</section>
