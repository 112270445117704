import {Component, Input, ViewChild, OnInit, ElementRef} from '@angular/core';
import {FormService} from "../../service/form/form.service";
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input() formId;
  @ViewChild('formResponseText', { static: true }) formResponseTextRef: ElementRef;

  public inputs;
  public formStatus = 0; // 0 = not submitted, 1 = success, 2 = error
  public submitting = false;
  public copying = false;
  private response:any = '';

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    try {
      this.formService.loadForm(this.formId).then((response) => this.inputs = response['fields']);
    } catch {
      this.formStatus = 2;
    }
  }

  /**
   * Checks an input to see if it has a value, if it is optional, and if it meets the required length
   * @param value
   * @param input
   * @returns {any}
   */
  checkInput(value, input) {
    if (input.optional === "0" || value.length > 0) {
      if (input.name === 'email') {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        input.valid = re.test(String(value));
      } else if (input.field_type === 'phone') {
        value = value.replace(/\D/g, '');
        input.valid = input.max_length ? value.length <= parseInt(input.max_length) : true;
      } else {
        input.valid = value.length > 0;
      }
      if (input.max_value && value.length > parseInt(input.max_length)) {
        input.valid = false;
      }
      if (value.length < 1) {
        input.valid = false;
      }
    } else {
      input.value = '';
      input.valid = true;
    }

    input.value = value;
    return input['valid'];
  }

  /**
   * Loops through to check all inputs
   * @returns {Promise<boolean>}
   */
  private async checkAllInputs() {
    let valid = true;
    for (let input of this.inputs) {
      if (!this.checkInput(input.value ?? '', input)) {
        valid = false;
      }
    }
    return valid;
  }

  private encodeForm() {
    let uri = [];
    this.inputs.forEach(input => {
      uri.push(`${input.name}=${encodeURIComponent(input.value)}`);
    });
    return uri.join('&');
  }

  /**
   * Submits form if it is valid
   */
  async submitForm() {
    this.formResponseTextRef.nativeElement.hidden = true;
    this.submitting = true;
    try {
      if (await this.checkAllInputs()) {
        let types = {};
        let values = {};
        this.inputs.forEach(field => {
          types[field.name] = field.field_type;
          values[field.name] = field.value;
        });
        const formData = {
          formId : this.formId,
          values : values,
          fieldTypes: types
        };
        this.response = await this.formService.submitForm(formData);
        this.formStatus = 1;
        this.formResponseTextRef.nativeElement.hidden = false;
        this.formResponseTextRef.nativeElement.innerHTML = this.response;
      }
    } catch (err) {
      this.formStatus = 2;
    } finally {
      this.submitting = false;
    }
  }
}
