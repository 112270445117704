import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SearchService} from "../../service/search/search.service";
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {

  public query: string;
  public title = 'Search';
  public searchInVal: string;
  public loading = false;
  public orderByVal: string;
  public results = [];
  private reachedBottom = false;
  private routeSub;

  constructor(
      private router: Router,
      private datePipe: DatePipe,
      private route: ActivatedRoute,
      private searchService: SearchService,
      private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
      this.searchInVal = "everything";
      this.orderByVal = "relevance";
      this.routeSub = this.route.url.subscribe(url => {
          this.query = (this.route.snapshot.paramMap.get('query')) ? this.route.snapshot.paramMap.get('query') : '';
          this.search();
      });
  }

  ngOnDestroy(){
      this.routeSub.unsubscribe();
      this.query = "";
      this.results = [];
  }

    /**
     * Change standard date into a readable date
     * @param date
     * @returns {any}
     */
  transformDate(date){
    return this.datePipe.transform(new Date(date), 'MMMM d, YYYY');
  }

    /**
     * Loops through all results and formats the date to be more readable
     * @param items
     */
  transformDates(items){
      for (let item of items){
          if (item.published_at){
              item.published_at = this.transformDate(item.published_at);
          }
      }
      return items;
  }

    /**
     * Get new set of results
     */
  async getResults() {
    let hitBottom = Math.round(this.results.length/window['NUM_ARCHIVE']);
    if (!this.reachedBottom && !this.loading && this.results.length === hitBottom*window['NUM_ARCHIVE']) {
        this.loading = true;
        this.googleAnalyticsService.sendAction("search", {search_term: this.query, category: "Search load more results", label: this.query});
        let nextPage = await this.searchService.search(this.query, this.orderByVal, this.searchInVal, hitBottom*window['NUM_ARCHIVE']);
        if (nextPage.length) {
          this.results = this.results.concat(this.transformDates(nextPage));
        } else {
          this.reachedBottom = true;
        }
        this.loading = false;
    }
  }

    /**
     * Handles a keypress, listening for enter to be pressed to fire a new search
     * @param event
     */
  handleKeydown(event){
      if (event.key === 'Enter'){
          this.navigateForSearch();
      }
  }

  // Change location to maintain search history
  navigateForSearch(){
      if (this.route.snapshot.paramMap.get('query').toString() !== this.query){
          this.results = [];
          this.router.navigateByUrl('/search/' + this.query);
      }
  }

    /**
     * Handles scrolling to load more results if the bottom of the page is reached and there are more results available
     * @param event
     */
    @HostListener('document:scroll', ['$event']) handleScroll(event){
        if ((window.scrollY + window.innerHeight === document.documentElement.scrollHeight) && this.results.length > 0) {
            this.getResults();
        }
    }

    /**
     * Do a new search query
     * @param queryParam
     */
    search(queryParam?){
        this.results = [];
        this.reachedBottom = false;
        if (queryParam){
            this.query = queryParam;
        }
        if (this.query !== ''){
            this.getResults();
        }
    }
}
