import { Injectable } from '@angular/core';
import {DrupalService} from "../drupal/drupal.service";

@Injectable()
export class FormService {


  constructor(
      private drupalService: DrupalService
  ) { }

  async loadForm(id){
    return this.drupalService.getForm(id);
  }

  getForms(){
    return this.drupalService.getForms();
  }

  async submitForm(data){
    return this.drupalService.submitForm(data);
  }

}
