import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnInit
} from "@angular/core";

@Component({
  selector: 'app-animate-value',
  templateUrl: './animate-value.component.html',
})
export class AnimateValueComponent implements AfterViewInit, OnChanges, OnInit {

  @Input() last: boolean = false;
  @Input() duration: number = 500;
  @Input() start: number = 0;
  @Input() digit: number = 0;
  @Input() steps: number = 12;
  @Input() trigger: boolean;
  @Output() triggered = new EventEmitter<boolean>();
  @ViewChild("animatedDigit") animatedDigit: ElementRef;

  viewInit: boolean;
  currentValue: number = 0;

  ngOnInit() {
    if (!this.viewInit) {
      this.viewInit = false;
    }
    this.currentValue = 0;
  }

  counterFunc(endValue, durationMs, element) {

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = endValue / stepCount;
    this.currentValue = this.start;

    const step = () => {
      this.currentValue += valueIncrement;

      if (this.currentValue > endValue) this.currentValue = endValue;
      if (this.viewInit) {
        element.nativeElement.textContent = Math.abs(this.currentValue).toFixed(1) + "%";
      }

      if (this.currentValue < endValue) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  ngAfterViewInit() {
    this.viewInit = true;
    this.counterFunc(this.digit, this.duration, this.animatedDigit);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["trigger"] && this.viewInit) {
      this.counterFunc(this.digit, this.duration, this.animatedDigit);
    }
    if(this.last) {
      this.triggered.emit(true);
    }
  }
}
