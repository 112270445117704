<section class="card">
  <h3>
    Company Calendar
  </h3>
  <div class="calendar-box">
    <div class="calendar-collection">
      <div #calendarContainer id="calendar-container">
        <div class="header">
          <h1>{{getMonthName()}} {{getYear()}}</h1>
          <div class="page-right-icon calendar-pager" (click)="nextMonth()"></div>
          <div class="page-left-icon calendar-pager" (click)="prevMonth()"></div>
        </div>
      </div>
      <div id="calendar-list-container">
        <h4 class="event-list-header selected-day">Today</h4>
        <ul class="selected-day-list">
          <li class="event-list-item" *ngFor="let event of currentDayEvents">
            <span *ngIf="event.page === ''" [innerHTML]="event.title"></span>
            <a *ngIf="event.page !== ''" [innerHTML]="event.title" routerLink="{{event.page}}"></a>
            <span *ngIf="event.duration === 'START'"> begins</span><span *ngIf="event.duration === 'END'"> ends</span>
            <span *ngIf="event.location" class="event-location"> ({{event.location}})</span>:
            <div class="event-right" *ngIf="event.duration === 'PART_DAY'">{{formatTime(event.start_time)}} - {{formatTime(event.end_time)}}</div>
            <div class="event-right" *ngIf="event.duration === 'START'">{{formatTime(event.start_time)}}</div>
            <div class="event-right" *ngIf="event.duration === 'END'">{{formatTime(event.end_time)}}</div>
            <div class="event-right" *ngIf="event.duration === 'ALL_DAY'">All day</div>
          </li>
          <i class="no-events" *ngIf="currentDayEvents.length === 0">No events.</i>
        </ul>
      </div>
      <div id="calendar-list-month-container" *ngIf="isFullpage">
        <h4 class="event-list-header">This month</h4>
        <div class="selected-day-list">
          <div class="event-list-item" *ngFor="let event of currentMonthEvents">
            <span class="calendar-list-date" [innerHTML]="formatDate(event.start_time, event.end_time, event.all_day === 'On') + ': '"></span>
            <span *ngIf="event.page === ''" [innerHTML]="event.title"></span>
            <a *ngIf="event.page !== ''" [innerHTML]="event.title" routerLink="{{event.page}}"></a>
            <span *ngIf="event.location" class="event-location"> ({{event.location}})</span>
          </div>
          <i class="no-events" *ngIf="currentMonthEvents.length === 0">No events.</i>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: right" *ngIf="!isFullpage">
    <button class="btn-grey" routerLink="/calendar" [gaEvent]="{category: 'Calendar Widget', action: 'click', label: 'View More'}">View More</button>
  </div>
</section>
