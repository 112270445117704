<section #menuEl class="card" (window:resize)="onResize()">
  <h3>Today's Menu</h3>
  <div class="menu">
    <div class="menu-pager">
      <div style="display:flex">
        <span class="page-left-icon block" (click)="goBack()"
              [ngClass]="{'disabled' : !currentMenus[0] || (currentDay.getDay() === 1 && currentMenuIndex === 0)}"></span>
      </div>
      <div>
        <h4 [innerHTML]="locationName"></h4>
        <span class="date" *ngIf="currentMenus[currentMenuIndex]" [innerHTML]="menuDate"></span>
      </div>
      <div style="display:flex">
        <span class="page-right-icon block" (click)="goForward()"
              [ngClass]="{'disabled' : !currentMenus[0] || (currentDay.getDay() === 5 && !currentMenus[1]) || (this.currentDay.getDay() === 5 && currentMenuIndex === 1)}"></span>
      </div>
    </div>
    <div *ngIf="facilityHours" class="facility-hours-wrapper" style="border-bottom: 2px solid #dddddd">
      <div *ngIf="facilityHours['breakfast']" class="facility-hours"
           [ngStyle]="{'flex-direction':menuCardWidth <= 250 ? 'column' : 'row'}">
        <div>Breakfast Hours:</div>
        <div>{{ facilityHours['breakfast'] }}</div>
      </div>
      <div *ngIf="facilityHours['lunch']" class="facility-hours"
           [ngStyle]="{'flex-direction':menuCardWidth <= 250 ? 'column' : 'row'}">
        <div>Lunch Hours:</div>
        <div>{{ facilityHours['lunch'] }}</div>
      </div>
    </div>

    <ul #foodMenu class="menu-list">
      <li class="menu-item" *ngFor="let entry of dayMenu | keyvalue">
        <span class="item-station-name" [innerHTML]="entry.value.station"></span><br>
        <div>
          <span class="item-name">{{ entry.value.item }}</span>
          <span class="item-price">{{ entry.value.price }}</span>
        </div>
      </li>
      <li class="menu-item" style="text-align: center;" *ngIf="currentMenus[currentMenuIndex] && !loading && !dayMenu">
        No menu for today.
      </li>
    </ul>
    <div class="no-menu" *ngIf="!currentMenus[currentMenuIndex] && !loading">
      <div>Could not retrieve menu. Please check back later.</div>
    </div>
    <div *ngIf="facilityHours && facilityHours['store']" class="facility-hours-wrapper"
         style="border-top: 2px solid #dddddd">
      <div class="facility-hours" [ngStyle]="{'flex-direction':menuCardWidth <= 250 ? 'column' : 'row'}">
        <div>C-Store Hours:</div>
        <div>
          <div [ngStyle]="{'text-align':menuCardWidth <= 250 ? 'center' : 'end'}"
               *ngFor="let cstoreHour of facilityHours['store']">{{ cstoreHour }}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
