<div class="expand-toggle-container">
    <input class="hidden" type="checkbox" id="{{id}}">
    <label class="expand-toggle" for="{{id}}" [title]="title">
        <span class="location_arrow"></span>
        <span class="expand-toggle-text" [innerHTML]="title"></span>
        <ul class="expand-toggle-choices">
            <li *ngFor="let option of options" (click)="select(option)"><span [innerHTML]="option"></span></li>
        </ul>
    </label>
</div>
