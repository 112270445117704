<nav class="breadcrumbs" *ngIf="!loading">
  <ul>
    <li><a class="crumb" routerLink="/home" [innerHTML]="'Home'"
           [gaEvent]="{category: 'Breadcrumbs', action: 'click', label: 'Home'}"></a></li>
    <li *ngFor="let crumb of breadcrumbs">
      <a class="crumb" routerLink="{{ crumb.path.includes('/node') ? '/article/' + crumb.nid : crumb.path }}" [innerHTML]="crumb.title"
         *ngIf="crumb && crumb.field_homepage!=='On'"
         [gaEvent]="{category: 'Breadcrumbs', action: 'click', label: crumb.title}"></a>
      <a class="crumb" routerLink="/archive/{{crumb.field_article_type}}" [innerHTML]="crumb.title"
         *ngIf="crumb && crumb.field_homepage==='On'"
         [gaEvent]="{category: 'Breadcrumbs', action: 'click', label: crumb.title}"></a>
    </li>
    <li><a class="crumb-current" [innerHTML]="title"></a></li>
  </ul>
</nav>
<div class="breadcrumbs-placeholder" *ngIf="loading"></div>
