import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html'
})
export class CommonComponent implements OnInit {

  public showBrowserWarning = false;

  ngOnInit(): void {
    this.detectBadBrowser();
  }

  /**
   * Detects browser version, returning false if a supported browser
   */
  detectBadBrowser() {
    const agent = window.navigator.userAgent;
    const msie = agent.indexOf('MSIE ');
    const trident = agent.indexOf('Trident/');
    const firefox = agent.indexOf('Firefox/');
    const edge = agent.indexOf('Edge');

    this.showBrowserWarning = (msie > 0 || trident > 0 || firefox > 0 || edge > 0);
  }

  hideWarning() {
    this.showBrowserWarning = false;
  }
}
