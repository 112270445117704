<section class="card" [ngClass]="{'top-level-card' : topLevel}">
  <h3 class="special">
    Announcements: {{getLocation()}}
  </h3>
  <div #ancContainer class="announcements">
    <h4 class="announcement-title inline-link" *ngIf="!loading && announcements.length > 0" [innerHTML]="announcements[currentPip].title" (click)="readMore()" [gaEvent]="{category: 'Announcements Widget', action: 'click', label: announcements[currentPip].title}"></h4>
    <h4 class="announcement-title" *ngIf="!loading && announcements.length < 1">No announcements for this location.</h4>
    <div class="body-copy" *ngIf="!loading && announcements.length > 0" [innerHTML]="announcements[currentPip].summary"></div>
    <div class="standin-copy" *ngIf="loading"></div>
  </div>
  <div class="announcements-scroll">
    <button class="btn-grey" *ngIf="announcements[currentPip]" routerLink="/archive/{{getAnnouncementsLabel()}}"  [gaEvent]="{category: 'Announcements Widget', action: 'click', label: 'View More'}">View More</button>
    <button class="page-left-icon" (click)="pipBackward()" [gaEvent]="{category: 'Announcements Widget', action: 'click', label: 'Previous Announcement'}" *ngIf="announcements.length > 1"></button>
    <div class="announcement-pips">
      <div class="pips-container" *ngIf="announcements.length > 1">
        <span *ngFor="let pip of announcements; index as i" class="pip" id="pip{{i}}" [ngClass]="{'selected' : currentPip === i}" (click)="transitionToPip(i)"></span>
      </div>
    </div>
    <button class="page-right-icon" (click)="pipForward()" [gaEvent]="{category: 'Announcements Widget', action: 'click', label: 'Next Announcement'}" *ngIf="announcements.length > 1"></button>
  </div>
</section>
