<!-- Resources links -->
<hr *ngIf="responsive">
<h1 *ngIf="responsive">Resources</h1>
<ul *ngIf="responsive">
  <li *ngFor="let child of children">
    <a [innerHTML]="child.title" routerLink="/archive/{{child.field_article_type}}" *ngIf="child.field_homepage==='On'" [gaEvent]="{category: 'Article Resources', action: 'click', label: child.title}"></a>
    <a [innerHTML]="child.title" routerLink="{{child.path.includes('/node') ? '/article/' + child.nid : child.path}}" *ngIf="child.field_homepage!=='On'" [gaEvent]="{category: 'Article Resources', action: 'click', label: child.title}"></a>
  </li>
</ul>
<div class="resource-links" *ngIf="!responsive">
  <h3 [innerHTML]='title + " Resources"'></h3>
  <ul>
    <li *ngFor="let child of children">
      <a [innerHTML]="child.title" routerLink="/archive/{{child.field_article_type}}" *ngIf="child.field_homepage==='On'" [gaEvent]="{category: 'Article Resources', action: 'click', label: child.title}"></a>
      <a [innerHTML]="child.title" routerLink="{{child.path.includes('/node') ? '/article/' + child.nid : child.path}}" *ngIf="child.field_homepage!=='On'" [gaEvent]="{category: 'Article Resources', action: 'click', label: child.title}"></a>
    </li>
  </ul>
</div>
