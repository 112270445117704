import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {DrupalService} from "../drupal/drupal.service";

declare const gtag: any;

@Injectable()
export class RouteResolverService {
  constructor(
    private router: Router,
    private drupalService: DrupalService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const referrerPath = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
    const referrer = !referrerPath ? referrerPath : window["ORIGIN_URL"].slice(0, -1) + referrerPath;
    const url = state.url;
    const location = window["ORIGIN_URL"].slice(0, -1) + url;
    switch (route.data.state) {
      case 'archive':
        return this.resolveArchive(route, url, location, referrer);
      case 'article':
        //If article parameter is non-numeric. Check if this route is a URL alias

        if (isNaN(route.params.id)) {
          return this.resolveAlias(route, url, location, referrer);
        }
        return this.resolveArticle(route.params.id, url, location, referrer);
      case 'home':
        this.setViewProperties(url, "ReynoldsWorld", location, referrer);
        break;
      case 'search':
        this.setViewProperties(url, "Search", location, referrer);
        break;
      case 'polls':
        this.setViewProperties(url, "Previous Polls", location, referrer);
        break;
      case 'calendar':
        this.setViewProperties(url, "Calendar", location, referrer);
        break;
      case 'alias':
        return this.resolveAlias(route, url, location, referrer);
      default:
        this.setViewProperties(url, route.url[0].path.trim(), location, referrer);
    }
  }

  /**
   * Attempt to resolve archive page
   *
   * @private
   * @param route
   * @param state
   */
  private resolveArchive(route: ActivatedRouteSnapshot, url: string, location: string, referrer: string) {
    return this.drupalService.getBlogHomepage(route.params.type).then(response => {
      if (!response[0]) {
        //If archiveType found with that name. Check for URL aliases.
        return this.resolveAlias(route, url, location, referrer);
      }
      this.setViewProperties(url, route.params.type, location, referrer);
      return response[0];
    }).catch(error => {
      if (error.status !== 403) {
        this.pageNotFound()
      } else {
        this.router.navigateByUrl('/login-error');
      }
    });
  }

  /**
   * Attempt to resolve node as an article.
   *
   * @private
   * @param nid
   * @param url
   */
  private resolveArticle(nid: number|string, url: string, location: string, referrer: string) {

    return this.drupalService.getNode(nid).then(response => {
      if (!response[0]) {
        this.pageNotFound()
      }

      const article = response[0];
      const articleHasUrlAlias = article.path && !article.path.includes('/node');

      if (articleHasUrlAlias) {
        let queryParamIndex = location.indexOf('?');
        let aliasedUrl = article.path
        if ( queryParamIndex >= 0 ) {
          aliasedUrl += location.substring(queryParamIndex);
        }
        this.router.navigateByUrl(aliasedUrl, { replaceUrl: true });
      } else {
        this.setViewProperties(url, article.title.trim(), location, referrer);
      }
      return article;
    }).catch(error => {
      if (error.status !== 403) {
        this.pageNotFound()
      } else {
        this.router.navigateByUrl('/login-error');
      }
    });
  }

  private resolveAlias(route: ActivatedRouteSnapshot, url: string, location: string, referrer: string) {
    return this.drupalService.getNodeByAlias(`/${route.url[0]['path']}` ?? url).then(response => {

      if (!response[0]) {
        this.pageNotFound();
      }
      const article = response[0];
      const title = article.title ?? route.url[route.url.length - 1].path.trim();
      this.setViewProperties(url, title, location, referrer);
      return article;
    }).catch(error => {
      if (error.status !== 403) {
        this.pageNotFound()
      } else {
        this.router.navigateByUrl('/login-error');
      }
    });
  }

  private setViewProperties(url: string, title: string, location: string, referrer: string) {
    document.title = title;
    gtag('js', new Date());
    gtag('config', window['ANALYTICS_ID'], {
      'send_page_view': false,
      'page_path': url,
      'page_location': location,
      'page_referrer': referrer,
      'page_title': title,
      'update': true
    });

    gtag('event', 'page_view')
  }

  private pageNotFound() {
    document.title = '404: Page Not Found';
    return this.router.navigateByUrl('not_found');
  }
}
