import {Component, Input, ElementRef, Renderer2, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {QuickLinkService} from "../../service/quick-link/quick-link.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  @Input() id;
  @ViewChild('customLinks', {static: true}) customLinksList: ElementRef;

  public editing = false;
  public links = [];

  private quickLinkSub;

  constructor(
    private quickLinkService: QuickLinkService,
    private renderer: Renderer2
  ) {
  }

  async ngOnInit() {
    this.links = await this.quickLinkService.getQuickLinks();
    this.quickLinkSub = this.quickLinkService.quickLinkStream.subscribe(ql => {
      this.links = ql;
      if (this.links.length === 0) {
        this.editing = false;
      }
    });
  }

  ngOnDestroy() {
    this.quickLinkSub.unsubscribe();
  }

  reorderLinks(event: any) {
    if (this.links.length <= 1 || !this.editing || event.target.classList.contains('remove-icon')) {
      return;
    }

    let isSwapping = false;
    const list = this.customLinksList.nativeElement;
    const target = event.target.closest('.sidebar-link');
    const mouseOffset = event.clientY - target.getBoundingClientRect().top + list.getBoundingClientRect().top;

    //hide list item to manage spacing without calculations
    target.style.visibility = 'hidden';

    //create a clone which will be the dragged element
    const dragEl = target.cloneNode(true);
    dragEl.style.zIndex = '1000';
    dragEl.style.visibility = 'visible';
    dragEl.style.position = 'absolute';
    dragEl.style.width = `${target.offsetWidth}px`;
    dragEl.style.height = `${target.offsetHeight}px`;
    dragEl.style.top = `${target.getBoundingClientRect().top - list.getBoundingClientRect().top}px`

    list.appendChild(dragEl);

    const onDrag = (event: MouseEvent) => {
// distance of dragEl from top of list
      const dragY = Math.min(list.offsetHeight - target.offsetHeight, Math.max(0, event.clientY - mouseOffset));
      dragEl.style.top = `${dragY}px`;
      if (isSwapping) {
        return;
      }
      const targetIdx = parseInt(target.getAttribute('data-index'));
      const targetLink = this.links[targetIdx];
      // distance of original list item from top of list
      const targetY = target.getBoundingClientRect().top - list.getBoundingClientRect().top;
      const nextSiblingExists = target.nextSibling && target.nextSibling.tagName;
      const prevSiblingExists = target.previousSibling && target.previousSibling.tagName;
      // If dragY is below the halfway point of target, swap the position of target and its next sibling
      if (nextSiblingExists && dragY > targetY + (target.nextSibling.offsetHeight / 2)) {
        isSwapping = true;
        const swap = target.nextSibling;

        this.renderer.addClass(swap, 'animate-swap');
        this.renderer.setStyle(target, 'transform', `translateY(${swap.offsetHeight}px)`);
        this.renderer.setStyle(swap, 'transform', `translateY(-${target.offsetHeight}px)`);
        //swap elements in dom after animation completes
        setTimeout(() => {
          this.renderer.removeClass(swap, 'animate-swap');
          this.links[targetIdx] = this.links[targetIdx + 1];
          this.links[targetIdx + 1] = targetLink;
          this.renderer.setStyle(target, 'transform', `translateY(0)`);
          this.renderer.setStyle(swap, 'transform', `translateY(0)`);
          isSwapping = false;
        }, 150);
      }

      // If dragY is above the halfway point of target, swap the position of target and its prev sibling
      if (prevSiblingExists && dragY < targetY - (target.previousSibling.offsetHeight / 2)) {
        isSwapping = true;
        const swap = target.previousSibling;

        this.renderer.addClass(swap, 'animate-swap');
        this.renderer.setStyle(target, 'transform', `translateY(-${swap.offsetHeight}px)`);
        this.renderer.setStyle(swap, 'transform', `translateY(${target.offsetHeight}px)`);
        //swap elements in dom after animation completes
        setTimeout(() => {
          this.renderer.removeClass(swap, 'animate-swap');
          this.links[targetIdx] = this.links[targetIdx - 1];
          this.links[targetIdx - 1] = targetLink;
          this.renderer.setStyle(target, 'transform', `translateY(0)`);
          this.renderer.setStyle(swap, 'transform', `translateY(0)`);
          isSwapping = false;
        }, 150);
      }
    }

    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', onDrag);
      this.renderer.removeChild(list, dragEl);
      this.renderer.setStyle(target, 'visibility', 'visible');
    });
  }

  // Turn edit off and apply changes
  apply() {
    this.editing = false;
    this.quickLinkService.saveLinks(this.links);
  }

  /**
   * Remove a quicklink from saved quicklinks by title
   * @param event
   * @param title
   */
  removeQuicklink(event: MouseEvent, title: string) {
    event.preventDefault();
    this.quickLinkService.removeLink(title);
  }

  // Get statically defined, required links
  getStaticLinks() {
    return window['REQUIRED_QUICK_LINKS'];
  }
}
