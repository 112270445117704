import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from "../common/not-found/not-found.component";
import {CssAuthValidationComponent} from "../auth/css-auth-validation/css-auth-validation.component";
import {AuthErrorComponent} from "../auth/auth-error/auth-error.component";

const routes: Routes = [
  {path: 'css-auth-validation', component: CssAuthValidationComponent},
  {path: 'login-error', component: AuthErrorComponent},
  {path: 'assets/silent-refresh.html', children: []},
  {path: 'not_found', component: NotFoundComponent},
  {path: '', loadChildren: () => import('../common/RW-common.module').then(m => m.RWCommonModule)},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
