import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class SearchService {

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Performs a search on either title or body
   * @param query
   * @param orderBy
   * @param searchIn
   * @param offset
   * @returns {string}
   */
  search(query, orderBy, searchIn, offset) {
    let URL = "";
    searchIn = searchIn.toLowerCase();
    if (orderBy.toLowerCase() === 'title') {
      URL += "_by_title";
    } else if (orderBy.toLowerCase() === 'date') {
      URL += "_by_date";
    } else {
      URL += "_by_relevance";
    }
    URL += "?" + ((searchIn === 'everything') ? "fulltext" : searchIn) + "=" + query;
    URL = window['SOLR_URL'] + URL + "&offset=" + offset + "&_format=json";
    return this.http.get<any>(URL).toPromise();
  }

  /**
   * Searches an archive for a specific article
   * @param query
   * @param articleType
   * @param offset
   * @param filters
   * @returns {Promise<any>}
   */
  doArchiveSearch(query, articleType, offset, filters?) {
    let url = `${window['SOLR_URL']}_by_archive?fulltext=${query}&field_article_type=${articleType}&offset=${offset}&_format=json`;
    if (filters && filters.length) {
      filters.forEach(filter => url += `&${filter.term}=${filter.values}`);
    }
    return this.http.get<any>(url).toPromise();
  }

}
