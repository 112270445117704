<aside class="sidebar-content">
  <h4>My Quick Links</h4>
  <ul class="quick-links">
    <li *ngFor="let link of getStaticLinks()">
      <img class="quick-link-icon" src="/assets/svg/{{link.icon}}">
      <a href="{{link.href}}" [ngStyle]="{'color': link.title === 'Emergencies' ? 'red' : ''}"
      [gaEvent]="{category: 'Sidebar', action: 'click', label: link.title}" [innerHTML]="link.title"></a>
    </li>
  </ul>
  <ul #customLinks class="quick-links custom-links">
    <li *ngFor="let link of links; let i = index" [ngClass]="{'rearrange' : editing, 'disable-sort' : !editing}"
        (mousedown)="reorderLinks($event)" class="sidebar-link" [attr.data-index]="i">
      <span class="remove-icon" (click)="removeQuicklink($event, link.title)" style="z-index: 10001"></span>
      <div style="display: flex">
        <img class="quick-link-icon" src="/assets/svg/{{link.icon}}" draggable="false">
        <a [routerLink]="link.href" draggable="false"
           [gaEvent]="{category: 'Sidebar', action: 'click', label: link.title}">{{link.title}}</a>
        <span class="fa fa-bars rearrange-quicklink-icon"></span>
      </div>
    </li>
  </ul>
  <button class="btn-grey" (click)="editing = true" *ngIf="!editing && links.length > 0"
          [gaEvent]="{category: 'Sidebar', action: 'click', label: 'Edit Quick Links'}">Edit Quick Links
  </button>
  <button class="btn-grey" (click)="apply()" *ngIf="editing"
          [gaEvent]="{category: 'Sidebar', action: 'click', label: 'Apply Quick Links'}">Apply
  </button>
</aside>
