<main class="main-with-sidebar">
  <div class="article-grid">
    <section class="col-left">
      <app-sidebar></app-sidebar>
    </section>
    <article class="col-middle-three">
      <app-breadcrumbs></app-breadcrumbs>
      <div class="article-text">
        <h1 [innerHTML]="title + ' ReynoldsWorld'"></h1>
      </div>
      <div class="page-search-container">
        <input type="text" class="page-search" (keydown)="handleKeydown($event)" placeholder="Search all pages" [(ngModel)]="query">
        <button class="page-search-btn" (click)="navigateForSearch()"></button>
      </div>
      <div class="search-show" style="margin-bottom:8px;">
        <div class="showing-results" *ngIf="results && results.length > 0">
          <span style="margin-right:10px;">Showing results</span>
          <span>1-{{results.length}}</span>
        </div>
        <div class="search-filter">
          <span style="margin-right:2px;">Order by:</span>
          <select id="search-filter-dropdown" (change)="search()" [(ngModel)]="orderByVal">
            <option value="relevance" [gaEvent]="{category: 'Search OrderBy', action: 'choose', label: 'Relevance'}">Relevance</option>
            <option value="date" [gaEvent]="{category: 'Search OrderBy', action: 'choose', label: 'Date'}">Date</option>
            <option value="title" [gaEvent]="{category: 'Search OrderBy', action: 'choose', label: 'Title'}">Title</option>
          </select>
        </div>
        <div class="search-filter">
          <span style="margin-right:2px;">Search in:</span>
          <select id="search-in-dropdown" (change)="search()" [(ngModel)]="searchInVal">
            <option value="everything" [gaEvent]="{category: 'Search SearchIn', action: 'choose', label: 'Everything'}">Everything</option>
            <option value="title" [gaEvent]="{category: 'Search SearchIn', action: 'choose', label: 'Title'}">Title</option>
            <option value="body" [gaEvent]="{category: 'Search SearchIn', action: 'choose', label: 'Body'}">Body</option>
          </select>
        </div>
      </div>
      <div class="search-results">
        <div *ngFor="let item of results">
          <hr>
          <a class="header-5 inline-link" [innerHTML]="item.title" routerLink="/archive/{{item.field_article_type}}" *ngIf="item.field_homepage==='On'" [gaEvent]="{category: 'Search Result', action: 'search', label: item.title}"></a>
          <a class="header-5 inline-link" [innerHTML]="item.title" routerLink="/article/{{item.nid}}" *ngIf="item.field_homepage!=='On'" [gaEvent]="{category: 'Search Result', action: 'search', label: item.title}"></a>
          <p class="body-copy" [innerHTML]="item.published_at"></p>
        </div>
        <div *ngIf="results.length === 0 && !loading" class="no-results">No results.</div>
        <div class="loader-fullpage-container" *ngIf="loading">
          <div class="loader-large"></div>
        </div>
      </div>
    </article>
  </div>
</main>
