import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../service/auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'css-auth-validation',
  templateUrl: './css-auth-validation.component.html'
})
export class CssAuthValidationComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  private redirectUrl = "/home";

  ngOnInit(): void {
    // Parse given auth token from CSS
    let responseData = window.location.href.split('?')[1].split('&');
    let responseChunks = [];
    responseData.forEach(chunk => {
      let pieces = chunk.split('=');
      responseChunks[pieces[0]] = pieces[1];
    });

    this.authService.completeAuthentication().then(data => {
      this.authService.isLoggedIn().then(isAuthenticated => {
        const redirectUri = isAuthenticated ? this.authService.redirectUriAfterLogin : '/login-error';
        this.router.navigateByUrl(redirectUri);
      }).catch(error => {
        console.error(error);
        this.router.navigateByUrl('/login-error');
      });
    }).catch(error => {
      this.router.navigateByUrl("/login-error");
    })
  }

}
