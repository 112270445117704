import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CssAuthValidationComponent } from './css-auth-validation/css-auth-validation.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [CssAuthValidationComponent, AuthErrorComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
})
export class AuthModule { }
