import {Injectable} from '@angular/core';
import {User as OidcUser, UserManager} from 'oidc-client-ts';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {GoogleAnalyticsService} from "../google-analytics/google-analytics.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  get redirectUriAfterLogin() {
    return localStorage.getItem('redirectUriAfterLogin')
  }

  set redirectUriAfterLogin(uri: string) {
    localStorage.setItem('redirectUriAfterLogin', uri)
  }

  private manager: UserManager;

  constructor(
    private http: HttpClient,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.manager = new UserManager(window['OIDC_CONFIG']);
  }

  getUser(): Promise<OidcUser> {
    return this.manager.getUser();
  }

  login() {
    return this.manager.signinRedirect();
  }

  renewToken() {
    return this.manager.signinSilent().then(async data => {
      if (data) await this.validateToken(data);
      return data;
    }).catch(error => {
      console.error(error);
      return this.login();
    });
  }

  async isLoggedIn() {
    let user = await this.getUser();
    return user?.expired === false;
  }

  completeAuthentication(): Promise<any> {
    this.manager.clearStaleState();
    return this.manager.signinRedirectCallback().then(data => {
      return this.validateToken(data).then(response => {
        this.googleAnalyticsService.sendAction("login", {method: "CSS", category: "CSS Login", label: "login"});
        return response;
      });
    }).catch(error => {
      console.error(error);
      this.router.navigateByUrl("/login-error");
    });
  }

  validateToken(data) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + data['access_token'],
    });
    return this.http.post(window['DRUPAL_URL'] + 'login', null, {headers}).toPromise();
  }

}
