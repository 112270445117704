import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges, Renderer2
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DrupalService} from "../../../service/drupal/drupal.service";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-archive-filters',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './archive-filters.component.html',
  styleUrls: ['./archive-filters.component.scss']
})
export class ArchiveFiltersComponent implements OnInit, OnChanges {
  @Input() articleType: string
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  filters = [];
  queryTerm = '';
  val = '-1';

  constructor(private drupalService: DrupalService, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.updateCategories();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateCategories();
  }

  updateCategories() {
    switch (this.articleType.toLowerCase()) {
      case 'current news':
        this.drupalService.getNewsArticleTags().then(result => this.filters = result);
        this.queryTerm = 'news_tag_filter';
        break;
      case 'associate spotlight':
        this.drupalService.getAssocSpotlightTags().then(result => this.filters = result);
        this.queryTerm = 'spotlight_tag';
        break;
      default:
        this.filters = [];
        break;
    }
  }

  select(event) {
    let filterIndex = event.target.value;
    if (filterIndex === -1 || !this.filters[filterIndex]?.name) {
      this.selected.emit([]);
      return;
    }

    this.selected.emit([{term: this.queryTerm, values: this.filters[filterIndex]?.name}]);
  }

  reset() {
    this.val = '-1';
  }
}
