<!-- Page header -->
<app-header></app-header>

<!-- Page content -->
<router-outlet style="margin-top:130px"></router-outlet>

<!-- Page Footer -->
<app-footer></app-footer>

<div class="browser-warning accent-font small-shadowed" *ngIf="showBrowserWarning">
    <div style="display:inline-block">
        <div class="browser-warning-main">ReynoldsWorld is not optimized for your browser!</div>
        <div class="browser-warning-sub">You may experience unexpected behavior.</div>
    </div>
    <span class="close-icon" (click)="hideWarning()"></span>
</div>
