import {Component, OnInit} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";

@Component({
  selector: 'app-associate-spotlight',
  templateUrl: './associate-spotlight.component.html',
  styleUrls: ['./associate-spotlight.component.scss']
})
export class AssociateSpotlightComponent implements OnInit {

  pages = [];

  constructor(
      private drupalService: DrupalService
  ) { }

    /**
     * On init, get associate spotlight pages
     */
  ngOnInit() {
    this.drupalService.getArticlePreview(window['ASSOC_SPOT_LABEL']).then(response => {
     this.pages = response.slice(0, window['NUM_ASSOC_SPOT_ITEMS']);
    });
  }

  getAssocSpotLabel(){
    return window['ASSOC_SPOT_LABEL'];
  }

  getNumStandins(){
      return window['NUM_STANDINS'];
  }

}
