<main class="main" (window:resize)="onResize($event)">
  <div class="homepage-large" *ngIf="!smallHomeSize">
    <div class="col-three-wide">
      <div class="col-two-wide">
        <app-jumbo></app-jumbo>
      </div>
      <div class="col-one-right">
        <app-announcements [topLevel]="true"></app-announcements>
      </div>
    </div>
    <div class="homepage-grid">
      <div class="col-left">
        <app-flash-poll></app-flash-poll>
        <app-quick-links></app-quick-links>
        <app-company-calendar></app-company-calendar>
        <app-food-menu></app-food-menu>
      </div>
      <div class="col-middle">
        <app-current-news></app-current-news>
      </div>
      <div class="col-right">
        <app-associate-spotlight></app-associate-spotlight>
        <app-hoorey></app-hoorey>
        <app-weather></app-weather>
      </div>
    </div>
  </div>
  <div class="homepage-small" *ngIf="smallHomeSize && !tinyHomeSize">
    <div class="col-two-wide top">
      <app-jumbo></app-jumbo>
    </div>
    <div class="homepage-grid">
      <div class="col-left">
        <app-announcements [topLevel]="false"></app-announcements>
        <app-associate-spotlight></app-associate-spotlight>
        <app-hoorey></app-hoorey>
        <app-flash-poll></app-flash-poll>
        <app-food-menu></app-food-menu>
        <app-quick-links></app-quick-links>
        <app-weather></app-weather>
        <app-company-calendar [isFullpage]="false"></app-company-calendar>
      </div>
      <div class="col-middle">
        <app-current-news></app-current-news>
      </div>
    </div>
  </div>
  <div class="homepage-small" *ngIf="tinyHomeSize">
    <div class="col-two-wide top">
      <app-jumbo></app-jumbo>
    </div>
    <div class="homepage-grid">
      <div class="col-left">
        <app-announcements [topLevel]="false"></app-announcements>
        <app-current-news></app-current-news>
        <app-associate-spotlight></app-associate-spotlight>
        <app-hoorey></app-hoorey>
        <app-flash-poll></app-flash-poll>
        <app-food-menu></app-food-menu>
        <app-quick-links></app-quick-links>
        <app-weather></app-weather>
        <app-company-calendar [isFullpage]="false"></app-company-calendar>
      </div>
    </div>
  </div>
</main>
