import {OnInit, OnDestroy, Component, ElementRef, ViewChild, Renderer2} from '@angular/core';
import {LocationService} from "../../service/location/location.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html'
})
export class WeatherComponent implements OnInit, OnDestroy {

  @ViewChild('weatherHolder', { static: true }) weatherHolderRef: ElementRef;

  public loading;
  private locationSub: Subscription;
  constructor(private locationService: LocationService, private renderer: Renderer2) {}

  ngOnInit() {
    this.applyTheme(this.locationService.getLocation());
    this.locationSub = this.locationService.locationStream.subscribe(loc => {
      this.applyTheme(loc);
    });
  }

  ngOnDestroy() {
    this.locationSub.unsubscribe();
  }

  /**
   * Updates the weather widget with new theme colors
   */
  applyTheme(city: string) {
    this.loading = true;

    let widgetAttributes = {
      'data-label_2': 'WEATHER',
      'data-font': 'Open Sans',
      'data-icons': 'Climacons Animated',
      'data-days': '3',
      'data-theme': 'pure',
      'data-highcolor': '#FF3851',
      'data-lowcolor': '#1E88E5',
      'data-suncolor': '#F9A20A',
    }

      switch (city.toLowerCase()) {
        case 'dayton':
          widgetAttributes['data-label_1'] = 'DAYTON';
          widgetAttributes['href'] = 'https://forecast7.com/en/39d76n84d19/dayton/?unit=us';
          break;
        case 'houston':
          widgetAttributes['data-label_1'] = 'HOUSTON';
          widgetAttributes['href'] = 'https://forecast7.com/en/29d76n95d37/houston/?unit=us';
          break;
        case 'college station':
          widgetAttributes['data-label_1'] = 'COLLEGE STATION';
          widgetAttributes['href'] = 'https://forecast7.com/en/30d63n96d33/college-station/?unit=us';
          break;
        case 'canada':
          widgetAttributes['data-label_1'] = 'MISSISSAUGA';
          widgetAttributes['href'] = 'https://forecast7.com/en/43d59n79d64/mississauga/';
          break;
        case 'united kingdom':
          widgetAttributes['data-label_1'] = 'BIRMINGHAM';
          widgetAttributes['href'] = 'https://forecast7.com/en/52d49n1d89/birmingham/';
          break;
      }

    const weatherWidgetLink = this.renderer.createElement('a');
    weatherWidgetLink.classList.add('weatherwidget-io');
    weatherWidgetLink.innerText = `${widgetAttributes['data-label_1']} WEATHER`;
    for (let [key, value] of Object.entries(widgetAttributes)) {
      weatherWidgetLink.setAttribute(key, value);
    }

    const script = this.renderer.createElement('script');
    script.src = 'https://weatherwidget.io/js/widget.min.js';

    this.weatherHolderRef.nativeElement.innerHTML = "";
    this.renderer.appendChild(this.weatherHolderRef.nativeElement, weatherWidgetLink);
    this.renderer.appendChild(this.weatherHolderRef.nativeElement, script);

    this.loading = false;
  }
}


