<section class="card">
  <div class="hoorey-icon"></div>
  <!-- Stand-ins -->
  <div *ngIf="!pages || pages.length===0">
    <div *ngFor="let page of getNumStandins(); let i = index">
      <div class="spotlight">
        <div class="standin-link"></div>
        <div class="standin-body-copy"></div>
      </div>
      <hr *ngIf="i < getNumStandins().length-1">
    </div>
  </div>
  <!-- Hoorey Items -->
  <div class="spotlight-holder">
    <div *ngFor="let page of pages; let i = index">
      <div class="spotlight">
        <a class="header-5 inline-link" [innerHTML]="page.title" routerLink="{{page.path.includes('/node') ? '/article/' + page.nid : page.path}}" [gaEvent]="{category: 'Hoorey Widget', action: 'click', label: page.title}"></a>
        <p class="body-copy" [innerHTML]="page.summary"></p>
      </div>
      <hr *ngIf="i < pages.length-1">
    </div>
  </div>
  <div style="text-align: right">
    <button class="btn-grey" routerLink="/archive/{{getHooreyLabel()}}" [gaEvent]="{category: 'Hoorey Widget', action: 'click', label: 'View More'}">View More</button>
  </div>
</section>
