<main class="main-with-sidebar">
  <div class="article-grid">
    <section class="col-left">
      <app-sidebar></app-sidebar>
    </section>
    <section class="col-middle-three">
      <div class="article-text">
        <h1>ReynoldsWorld Polls</h1>
        <p>Have a Weekly Poll suggestion? Email <a href="mailto:ReynoldsWorld@reyrey.com?subject=Weekly%20Poll%20suggestion">ReynoldsWorld</a>!</p>
      </div>
      <div class="page-search-container">
        <input type="text" class="page-search" (keydown.enter)="filterPolls()" placeholder="Search Polls" id="search"
               [(ngModel)]="workingQuery">
        <button class="page-search-btn" (click)="filterPolls()"></button>
        <div class="filter-links">
          <a (click)="resetSearch()">Clear Search</a>
          <a (click)="showFilters = !showFilters"><span *ngIf="showFilters">Hide</span><span
            *ngIf="!showFilters">Show</span> Filters</a>
        </div>
        <div *ngIf="showFilters" class="date-filter-container">
          <div class="date-filter">
            <span style="font-size: 14px; margin-right:2px;">From:</span>
            <input class="date-input" id="dateRangeStart" type="date" max='2040-01-01' [(ngModel)]="workingStart"
                   (change)="validateDate()">
          </div>
          <div class="date-filter">
            <span style="margin-right:2px; font-size: 14px;">To:</span>
            <input class="date-input" id="dateRangeEnd" type="date" [(ngModel)]="workingEnd" (change)="validateDate()">
          </div>
        </div>
      </div>
      <span class="no-results" *ngIf="!this.loading && !this.polls.length">No Results.</span>
      <div class="news-item" *ngFor="let poll of polls">
        <span class="date">{{poll.published}}</span>
        <h4 style="padding-bottom: 10px;">
          <span class="header">{{poll.question}}</span>
        </h4>
        <div>
          <div class="poll-option" *ngFor="let option of poll.choices; let i = index">
            <div class="option-container voted">
              <div class="results" [ngStyle]="{'width': poll.choices[i].results + '%'}"></div>
              <h5 class="options">{{option['choice']}}</h5>
              <h5 class="counter">{{poll.choices[i].results}}%</h5>
            </div>
            <h4>Votes: {{poll.choices[i].count}}</h4>
          </div>
          <h4 style="margin-top: 10px;">Total Votes: {{poll.total}}</h4>
        </div>
        <footer>
          <!--          <div class="show-results" [ngStyle]="{'margin-top': (poll.open) ? '0px' : '-35px'}">-->
          <!--            <a (click)="poll.open = !poll.open">View Results <span *ngIf="!poll.open">↓</span><span-->
          <!--              *ngIf="poll.open">↑</span></a>-->
          <!--          </div>-->
        </footer>
      </div>
      <div class="loader-fullpage-container" *ngIf="loading">
        <div class="loader"></div>
      </div>
    </section>
  </div>
</main>
