import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {DrupalService} from "../drupal/drupal.service";

@Injectable()
export class LocationService {

    private currentLocation = new Subject<string>();
    locationStream = this.currentLocation.asObservable();
    private location;
    locations = [];
    locationData = {};
    locPromise;

    constructor(private drupalService: DrupalService) {
        if (localStorage.getItem('location')){
            this.changeLocation(localStorage.getItem('location'));
        } else {
            this.changeLocation('Dayton');
        }
        this.locPromise = this.drupalService.get('locations').then(response => {
          response.forEach(loc => {
            this.locations.push(loc.name);
            this.locationData[loc.name.toLowerCase()] = loc;
          });
        });
    }

    changeLocation(newLoc: string){
      this.location = newLoc;
      localStorage.setItem('location', newLoc);
      this.currentLocation.next(newLoc);
    }

    getLocation(){
      return this.location;
    }

    async getLocationTid() {
      await this.locPromise;
      return this.locationData[this.getLocation().toLowerCase()]['tid'];
    }

    async getFacilityHours() {
      await this.locPromise;
      const locData = this.locationData[this.getLocation().toLowerCase()];
      return {
        'breakfast': locData['breakfast_hours'],
        'lunch': locData['lunch_hours'],
        'store': locData['cstore_hours'] ? locData['cstore_hours'].split(', ') : null
      }
    }

    async getAllLocations() {
      await this.locPromise;
      return this.locations;
    }

}
