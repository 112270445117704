import { Component, OnInit } from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";

@Component({
  selector: 'app-hoorey',
  templateUrl: './hoorey.component.html'
})
export class HooreyComponent implements OnInit {

    public pages = [];

    constructor(
        private drupalService: DrupalService
    ) { }

    /**
     * On init, get current news pages
     */
    ngOnInit() {
        this.drupalService.getArticlePreview(window['HOOREY_LABEL']).then(response => {
            this.pages = response.slice(0, window['NUM_HOOREY_ITEMS']);
        });
    }

    getHooreyLabel(){
        return window['HOOREY_LABEL'];
    }

    getNumStandins(){
        return window['NUM_STANDINS'];
    }
}
