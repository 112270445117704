import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DrupalService} from "../../service/drupal/drupal.service";
import {ActivatedRoute} from "@angular/router";
import {SearchService} from "../../service/search/search.service";
import {GoogleAnalyticsService} from "../../service/google-analytics/google-analytics.service";
import {ArchiveFiltersComponent} from "./archive-filters/archive-filters.component";

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit, OnDestroy {
  @ViewChild(ArchiveFiltersComponent) private archiveFilters!: ArchiveFiltersComponent

  public posts = [];
  public articleType: string;
  public loading = false;
  public drupalImageUrl = window['DRUPAL_IMAGE_URL'];
  public page;
  public query = '';
  private routeSub;
  private filters = [];
  private reachedBottom = false;

  constructor(
    private drupalService: DrupalService,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.posts = [];
    this.query = "";
    this.page = undefined;
  }

  ngOnInit() {
    this.routeSub = this.route.data.subscribe(data => {
      this.page = data.pageData;
      this.articleType = this.page.field_article_type;
      this.query = '';
      this.getDefaultResults();
    });
  }

  /**
   * Listens for scroll events to load more results when the bottom of the page is reached
   */
  @HostListener('document:scroll') onScroll() {
    if (this.reachedBottom || this.loading || window.scrollY + window.innerHeight !== document.documentElement.scrollHeight) {
      return;
    } else if (this.query.trim()) {
      this.getSearchResults();
    } else {
      this.getArchiveResults();
    }
  }

  /**
   * Listens for an ENTER keypress to perform a new search
   * @param event
   */
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.searchChange();
    }
  }

  clearSearch() {
    this.query = "";
    this.filters = [];
    this.getDefaultResults();

    if (this.archiveFilters) {
      this.archiveFilters.reset();
    }
  }

  /**
   * Get the default archive results without filters or search parameters
   */
  async getDefaultResults() {
    this.loading = true;
    const posts = await this.drupalService.getArticlePreview(this.articleType).catch(error => console.error(error));
    this.loading = false;
    this.posts = this.getResultsTags(posts);
  }

  /**
   * Helper method to format a string as a class name in order to load the proper news icon
   * @param posts
   */
  getResultsTags(posts) {
    for (let page of posts) {
      if (page.news_tag && page.news_tag !== '') {
        page['news_tag'] = page.news_tag.replace(/&#039;/g, "\'");
      }
    }
    return posts;
  }

  /**
   * Gets new results when a new search is entered
   * @param event
   */
  searchChange() {
    this.posts = [];
    this.reachedBottom = false;
    if (this.query.trim() === '') {
      this.getDefaultResults();
    } else {
      this.getSearchResults();
    }
  }

  /**
   * Get more archive results without a search parameter if any are available
   * @returns {Promise<void>}
   */
  async getArchiveResults(): Promise<void> {
    const hitBottom = Math.round(this.posts.length / window['NUM_ARCHIVE']);
    if (this.posts.length === hitBottom * window['NUM_ARCHIVE']) {
      this.loading = true;
      const results = await this.drupalService.getMoreArchive(this.articleType, hitBottom * window['NUM_ARCHIVE'], this.filters);
      if (results.length) {
        this.posts = this.posts.concat(results);
        this.getResultsTags(this.posts);
      } else {
        this.reachedBottom = true;
      }
      this.loading = false;
    }
  }

  /**
   * Get more results with a search parameter if any are available
   */
  async getSearchResults() {
    const hitBottom = Math.round(this.posts.length / window['NUM_ARCHIVE']);
    this.query = this.query.trim();
    if (this.posts.length === hitBottom * window['NUM_ARCHIVE']) {
      this.loading = true;
      this.googleAnalyticsService.sendAction('search', {
        search_term: this.query,
        category: "Archive Load More Results",
        label: this.query
      });
      const results = await this.searchService.doArchiveSearch(this.query, this.articleType, hitBottom * window['NUM_ARCHIVE'], this.filters);
      if (results.length) {
        this.posts = this.posts.concat(await this.searchService.doArchiveSearch(this.query, this.articleType, hitBottom * window['NUM_ARCHIVE'], this.filters));
        this.getResultsTags(this.posts);
      } else {
        this.reachedBottom = true;
      }
      this.loading = false;
    }
  }

  applyFilters(filters: any) {
    this.filters = filters;
    if (this.query.trim() === '') {
      this.drupalService.getArticlePreview(this.articleType, this.filters).then(result => {
        this.posts = result
        this.posts = this.getResultsTags(this.posts);
      });
    } else {
      this.searchService.doArchiveSearch(this.query, this.articleType, 0, this.filters).then(result => {
        this.posts = result
        this.posts = this.getResultsTags(this.posts);
      });
    }
  }

  getDrupalImageUrl() {
    return window['DRUPAL_IMAGE_URL'];
  }
}
